// @flow
import React, { useState } from "react";
import { View, TextInput, Button, Linking, Text } from "react-native";
import { Icon } from "react-native-elements";

import { AuthContext } from "../../App";
import parameters from "../../app.json";
import Title from "../UI/Title";
import ConectaIdeasClient from "../components/ConectaIdeas/ConectaIdeasClient";
import Modal from "../components/Modal";
import Screens from "../constants/Screens";
import useConnectionStatus from "../hooks/useConnectionStatus";
import { styles } from "./styles";

export default function ThirdLoginScreen({ route }) {
  const [pass, setPassword] = useState("");
  const [securePass, setSecurePass] = useState(true);
  const [isError, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const conectaideas = new ConectaIdeasClient();
  const { signIn } = React.useContext(AuthContext);

  const isOnline = useConnectionStatus();

  const state = {
    user: route.params.user,
    institucion: route.params.institucion,
  };

  function performLogin() {
    setLoading(true);

    conectaideas
      .signIn(state.user, state.institucion, pass)
      .then((user) => {
        console.log("logged in user", user);

        signIn(user);
      })
      .catch((error) => {
        //usuario no existe no es un error que queramos reportar a sentry
        setError(true);
        setLoading(false);
        if (error.code !== "UserNotFoundException") {
          console.log("Error inicio sesión", JSON.stringify(error));
        }
      });
  }

  const forgotPassword = () => {
    console.log("forgotPassword");
    Linking.openURL(
      "https://www.conectaideas.com/sessions/forgot_password"
    ).then(() => {
      this.props.navigation.navigate(Screens.FirstLogin);
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.loginForm}>
        <Title>Usuario: {state.user} </Title>
        <View style={{ height: 20 }} />
        <Title>Contraseña</Title>
        <View style={styles.inputPasswordContainer}>
          <TextInput
            style={styles.inputPass}
            autoCapitalize={"none"}
            returnKeyType={"done"}
            autoCorrect={false}
            placeholder="Contraseña"
            secureTextEntry={securePass}
            onChangeText={(text) => setPassword(text)}
            testID="Password"
          />
          <Icon
            size={22}
            color="grey"
            name={securePass ? "visibility" : "visibility-off"}
            onPress={() => setSecurePass(!securePass)}
          />
        </View>

        <View style={{ height: 30 }} />
        <Button
          title={loading ? "Ingresando..." : "Ingresar"}
          style={styles.loginButton}
          onPress={performLogin}
          testID="Login"
          accessible={!(pass === null)}
          disabled={pass === null || loading}
        />

        <View style={{ height: 40 }} />

        <Button
          title="Olvidé mi contraseña"
          style={styles.forgotPasswordButton}
          onPress={forgotPassword}
          testID="ForgotPassword"
        />
      </View>
      <Text style={styles.version}>v. {parameters.expo.version}</Text>
      {isError && (
        <Modal
          onPressButtonLeft={() => setError(false)}
          buttonLeft="OK"
          mensaje={
            isOnline ? "Contraseña incorrecta." : "Sin conexión a internet"
          }
        />
      )}
    </View>
  );
}
