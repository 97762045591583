import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import { useIsFocused } from "@react-navigation/native";
import React, { useState, useEffect, useContext } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { Icon, Button } from "react-native-elements";

import { AuthContext } from "../../App";
import Modal from "../components/Modal";
import StorageKeys from "../constants/StorageKeys";
import ActividadesEspecialesList from "./ActividadesEspecialesList";
import ObservacionList from "./ObservacionList";
import TicketDeSalidaList from "./TicketDeSalidaList";
import VideosList from "./VideosList";
import reloadEvaluations from "./reloadEvaluations";
import {
  sendVideos,
  sendEvaluations,
  sendObservations,
  getErrorMessage,
} from "./sendFunctions";
import { styles } from "./styles";

export default function PendingUploadScreen() {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { signOut } = useContext(AuthContext);
  const [registros, setRegistros] = useState({
    evaluations: [],
    observations: [],
    actividadesEspeciales: [],
    videos: [],
  });
  const [modalProperties, setModalProperties] = useState({
    buttonModalRight: null,
    buttonModalLeft: null,
    titleModal: null,
    mensajeModal: null,
    onPressButtonRight: null,
  });
  const [isOpenModal, setOpenModal] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (isFocused) {
      reloadEvaluations(setRegistros).then(() => {
        console.log("Records loaded");
      });
    }
  }, [isFocused, setRegistros]);

  const confirmarEnvio = () => {
    setModalProperties({
      titleModal: "Confirmar",
      mensajeModal:
        "La información y videos que subo son de mi exclusiva responsabilidad.\n\nRegistros no podrán ser modificadas una vez enviados. ¿Deseas continuar?",
      buttonModalLeft: "No",
      buttonModalRight: "Si, enviar",
      onPressButtonRight: () => enviar(),
    });
    setOpenModal(true);
  };

  const enviar = () => {
    console.log("sending");
    setSending(true);
    const promises = [];
    const evaluations = [];

    evaluations.push(sendEvaluations(registros.evaluations));
    evaluations.push(sendEvaluations(registros.actividadesEspeciales));
    promises.push(sendObservations(registros.observations));
    promises.push(sendVideos(registros.videos));

    const p1 = Promise.all(evaluations).then((result) => {
      return AsyncStorage.setItem(
        StorageKeys.evaluationKey,
        JSON.stringify({})
      );
    });
    promises.push(p1);

    Promise.all(promises)
      .then((result) => {
        console.log("Sending done", result);
        reloadEvaluations(setRegistros).then(() => {
          setSending(false);
        });
      })
      .catch((error) => {
        const mensaje = getErrorMessage(error.message);

        if (error.message === "Autherror") {
          setModalProperties({
            titleModal: null,
            mensajeModal: mensaje,
            buttonModalLeft: "OK",
            buttonModalRight: null,
          });
          setOpenModal(true);
          setSending(false);
          signOut();
        } else {
          setModalProperties({
            titleModal: null,
            mensajeModal: mensaje,
            buttonModalRight: null,
            buttonModalLeft: "OK",
          });
          setOpenModal(true);
          reloadEvaluations(setRegistros).then(() => {
            setSending(false);
          });
        }
        //Sentry.captureException(error);
      });
    setOpenModal(false);
  };

  if (sending) {
    return (
      <View>
        <Text>Enviando registros</Text>
        <ActivityIndicator size="small" color="#0000ff" />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <Button
        icon={<Icon name="add" color="white" />}
        title="Nuevo registro"
        onPress={() => {
          navigation.navigate("ClassSelection");
        }}
      />
      <View style={{ height: 20 }} />
      <View
        style={{
          flex: 1,
          alignItems: "center",
          paddingBottom: 20,
        }}
      >
        <TicketDeSalidaList
          evaluations={registros.evaluations}
          setRegistros={setRegistros}
          registros={registros}
          setOpenModal={setOpenModal}
          setModalProperties={setModalProperties}
          navigation={navigation}
        />
        <ActividadesEspecialesList
          actividadesEspeciales={registros.actividadesEspeciales}
          setRegistros={setRegistros}
          registros={registros}
          setOpenModal={setOpenModal}
          setModalProperties={setModalProperties}
          navigation={navigation}
        />
        <ObservacionList observations={registros.observations} />
        <VideosList videos={registros.videos} />
        <View style={{ height: 20 }} />
      </View>
      <Button title="Enviar" onPress={confirmarEnvio} />
      {isOpenModal && (
        <Modal
          title={modalProperties.titleModal}
          mensaje={modalProperties.mensajeModal}
          buttonRight={modalProperties.buttonModalRight}
          buttonLeft={modalProperties.buttonModalLeft}
          onPressButtonRight={modalProperties.onPressButtonRight}
          onPressButtonLeft={() => setOpenModal(false)}
        />
      )}
    </View>
  );
}
