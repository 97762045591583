import { StyleSheet, Platform } from "react-native";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: "#F5FCFF",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  loginForm: {
    justifyContent: "flex-start",
    flexDirection: "column",
    width: Platform.OS === "web" ? "40%" : "98%",
    marginHorizontal: Platform.OS === "web" ? "20%" : "1%",
    paddingHorizontal: Platform.OS === "web" ? 10 : 5,
    borderColor: "#0469FA",
    borderWidth: Platform.OS === "web" ? 1 : 0,
    marginTop: Platform.OS === "web" ? "10%" : 0,
    borderRadius: 7,
    paddingVertical: Platform.OS === "web" ? 20 : 5,
  },
  input: {
    height: 40,
    marginHorizontal: 20,
    paddingLeft: 45,
    borderRadius: 10,
    borderColor: "#c5c5c5",
    borderWidth: 1,
    color: "#000000",
  },
  inputPass: {
    height: 40,
    marginHorizontal: 20,
    paddingLeft: 45,
    borderRadius: 10,
    borderColor: "#c5c5c5",
    borderWidth: 1,
    color: "#000000",
    width: "80%",
  },
  loginButton: {
    flex: 1,
  },
  forgotPasswordButton: {
    flex: 1,
  },
  inputPasswordContainer: {
    flexDirection: "row",
    width: "100%",
  },
  version: {
    marginTop: 20,
  },
});
