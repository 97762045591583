import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";

import { ObservationStorageKey } from "../ObservacionClase/ObservationScreen";
import { VideosStorageKey } from "../Videos/VideoScreen";
import StorageKeys from "./../constants/StorageKeys";

export default async function reloadEvaluations(setState) {
  const a = AsyncStorage.getItem(StorageKeys.evaluationKey).then((data) => {
    return _.chain(JSON.parse(data))
      .values()
      .filter((k) => !_.isNull(k))
      .map((k) => {
        if (_.isUndefined(k.tipo)) {
          k.tipo = "Ticket de Salida";
        }
        return k;
      })
      .filter(
        (k) => k.tipo === "Ticket de Salida" || k.tipo === "Libro Colorea Ideas"
      )
      .sortBy("date")
      .reverse()
      .value();
  });

  const b = AsyncStorage.getItem(StorageKeys.evaluationKey).then((data) => {
    return _.chain(JSON.parse(data))
      .values()
      .filter(
        (k) =>
          !_.isNull(k) &&
          (k.tipo === "Actividad Monitoreo" || k.tipo === "Actividad Especial")
      )
      .map((k) => {
        k.tipo = "Actividad Monitoreo";
        return k;
      })
      .sortBy("date")
      .reverse()
      .value();
  });

  const c = AsyncStorage.getItem(ObservationStorageKey()).then((data) => {
    return _.chain(JSON.parse(data))
      .values()
      .filter((k) => !_.isNull(k))
      .sortBy("date")
      .reverse()
      .value();
  });

  const d = AsyncStorage.getItem(VideosStorageKey()).then((data) => {
    return _.chain(JSON.parse(data)).values().sortBy("date").reverse().value();
  });

  return Promise.all([a, b, c, d]).then((results) => {
    console.log(
      "a",
      results[0],
      "b",
      results[1],
      "c",
      results[2],
      "d",
      results[3]
    );
    setState({
      evaluations: results[0],
      actividadesEspeciales: results[1],
      observations: results[2],
      videos: results[3],
    });
  });
}
