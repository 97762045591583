export default {
  //this should be always set to false before committing changes
  //storybook only runs on ios: yarn run ios
  storyBookEnabled: false,
};

export const authConfig = {
  // Amazon Cognito Region
  region: "us-east-1",

  // Amazon Cognito User Pool ID
  userPoolId: "us-east-1_M8LxQk0c6",

  // Amazon Cognito Web Client ID
  userPoolWebClientId: "15r6d71a646d0pg1fv545fcmgi",

  // Authentication type
  authenticationFlowType: "USER_PASSWORD_AUTH",

  identityPoolId: "us-east-1:c1d69c26-330d-455e-ab03-e2ae29832499",

  identityPoolRegion: "us-east-1",
};

export const ConectaIdeasConfig = {
  year: 2022,
};

export const backend = "https://www.conectaideas.com/api/";
