import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";

const marginRight = 28;

export default function TopRightButton({ navigation }) {
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate("drawerStack");
      }}
    >
      <Icon name="menu" />
    </TouchableOpacity>
  );
}

StyleSheet.create({
  pressable: {
    backgroundColor: "rgba(255,255,255,0)",
    width: 90,
    height: 60,
    top: 0,
    right: 0,
    position: "absolute",
    paddingHorizontal: marginRight,
    justifyContent: "center",
    alignContent: "center",
  },
});
