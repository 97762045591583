/**
 * ConectaIdeas Express
 *
 * @format
 * @flow
 */
/* global __DEV__ */
import { Auth } from "@aws-amplify/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer } from "@react-navigation/native";
import * as SplashScreen from "expo-splash-screen";
import * as Updates from "expo-updates";
import React, { createContext, useEffect, useMemo, useReducer } from "react";
import { enableScreens } from "react-native-screens";
import * as Sentry from "sentry-expo";

import { authConfig } from "./Config";
import { getCurrentSession } from "./src/components/ConectaIdeas/ConectaIdeasClient";
import AppStackNavigator from "./src/navigation/AppStackNavigator";
import LinkingConfiguration from "./src/navigation/LinkingConfiguration";
import LoginStackNavigator from "./src/navigation/LoginStackNavigator";

enableScreens();

Sentry.init({
  dsn:
    "https://344f6605a2a34d14974a2157e76bbc9a@o310822.ingest.sentry.io/5792341",
  enableInExpoDevelopment: true,
  debug: __DEV__,
});

Auth.configure(authConfig);

export const AuthContext = createContext();

function loginReducer(state, action) {
  switch (action.type) {
    case Actions.SIGN_IN:
      return { loading: false, isAuthenticated: true };
    case Actions.SIGN_UP:
      return { loading: false, isAuthenticated: false };
  }
  throw new Error("Unknown action " + JSON.stringify(action));
}

const Actions = {
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
};

export default function App() {
  const [authenticationState, dispatch] = useReducer(loginReducer, {
    loading: true,
    isAuthenticated: false,
  });

  const authContext = useMemo(
    () => ({
      signIn: (data) => {
        if (data === undefined) {
          throw new Error("Auth token is missing");
        }
        dispatch({ type: Actions.SIGN_IN, token: data });
      },
      signUp: () => {
        dispatch({ type: Actions.SIGN_UP });
      },
      signOut: async () => {
        console.log("CIERRA SESION");
        const keys = await AsyncStorage.getAllKeys();
        keys.length > 0 && (await AsyncStorage.multiRemove(keys));
        await Auth.signOut();
        dispatch({ type: Actions.SIGN_UP });
      },
    }),
    []
  );

  useEffect(() => {
    SplashScreen.preventAutoHideAsync();
  }, []);

  useEffect(() => {
    Updates.addListener((event) => {
      console.log("Update OTA event", event);
      if (event.type === "updateAvailable") {
        console.log("New update available");
        Updates.reloadAsync().then(() => {
          console.log("Reload App");
        });
      }
    });
  }, []);

  useEffect(() => {
    getCurrentSession()
      .then((auth) => {
        authContext.signIn(auth);
      })
      .catch((error) => {
        authContext.signUp();
      })
      .finally(() => {
        return SplashScreen.hideAsync();
      });
  }, []);

  if (authenticationState.loading) {
    return null;
  }
  return (
    <AuthContext.Provider value={authContext}>
      <NavigationContainer linking={LinkingConfiguration}>
        {authenticationState.isAuthenticated ? (
          <AppStackNavigator />
        ) : (
          <LoginStackNavigator />
        )}
      </NavigationContainer>
    </AuthContext.Provider>
  );
}
