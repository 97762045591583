import _ from "lodash";
import React, { useEffect, useState } from "react";
import { View, Text, TouchableHighlight } from "react-native";

import { stylesStudent as styles } from "./styles";

export default function StudentAnswer({ student, onAnswer }) {
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    let answerTemp = null;
    if (!_.isUndefined(student.grade)) {
      if (student.grade === -1) {
        answerTemp = "wrong";
      }
      if (student.grade === 1) {
        answerTemp = "right";
      }
    }
    setAnswer(answerTemp);
  }, [student]);

  const right = () => {
    if (answer === "right") {
      setAnswer(null);
      onAnswer(0);
    } else {
      setAnswer("right");
      onAnswer(1);
    }
  };

  const wrong = () => {
    if (answer === "wrong") {
      setAnswer(null);
      onAnswer(0);
    } else {
      setAnswer("wrong");
      onAnswer(-1);
    }
  };

  return (
    <View style={styles.row}>
      <Text style={styles.name}>{student.nombre}</Text>
      <TouchableHighlight
        style={[
          styles.baseButton,
          answer === "wrong" ? styles.wrongSelected : styles.wrongDeselected,
        ]}
        onPress={wrong}
      >
        <Text
          style={[
            styles.baseTextButton,
            answer === "wrong" ? styles.white : styles.wrong,
          ]}
        >
          Mala
        </Text>
      </TouchableHighlight>
      <TouchableHighlight
        style={[
          styles.baseButton,
          answer === "right" ? styles.rightSelected : styles.rightDeselected,
        ]}
        onPress={right}
      >
        <Text
          style={[
            styles.baseTextButton,
            answer === "right" ? styles.white : styles.right,
          ]}
        >
          Buena
        </Text>
      </TouchableHighlight>
    </View>
  );
}
