import _ from "lodash";

export const computeState = (key, value, state, setState) => {
  const newState = { ...state };
  newState[key] = value;
  console.log(
    `newState.oas[${newState.subsector_id}][${newState.nivel}][${newState.eje_id}]`
  );
  if (
    !_.keys(newState.oas[newState.subsector_id]).includes(
      newState.nivel.toString()
    )
  ) {
    newState.nivel = _.keys(newState.oas[newState.subsector_id])[0];
    console.log("switching nivel to", newState.nivel);
  }
  if (
    newState.oas &&
    newState.oas[newState.subsector_id] &&
    newState.oas[newState.subsector_id][newState.nivel]
  ) {
    const ejes = _.chain(newState.oas[newState.subsector_id][newState.nivel])
      .keys()
      .value();

    if (!_.includes(ejes, newState.eje_id)) {
      console.log(`switching eje from ${newState.eje_id} to ${_.first(ejes)}`);
      newState.eje_id = _.first(ejes);
    }

    if (newState.oas[newState.subsector_id][newState.nivel][newState.eje_id]) {
      console.log("valid eje");
      const firstOa = _.chain(
        newState.oas[newState.subsector_id][newState.nivel][newState.eje_id]
      )
        .keys()
        .first()
        .value();
      console.log("firstOa", firstOa);
      newState.oa_id = firstOa;
    } else {
      // there is not available eje
      newState.eje_id = undefined;

      // there are not available OA
      newState.oa_id = undefined;
    }
  }
  setState(newState);
};
