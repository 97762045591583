import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation, useRoute } from "@react-navigation/core";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { View, ScrollView } from "react-native";
import { Button } from "react-native-elements";

import RenderQuestion from "./RenderQuestion";
import questionsList from "./questions.json";
import { styles } from "./styles";

const observationKey = "@conectaIdeas:observation";

export default function ObservationScreen() {
  console.log("Starting observation screen");
  const navigation = useNavigation();
  const route = useRoute();
  const [state, setState] = useState({
    buttonDisabled: true,
    answers: 0,
    questions: _.cloneDeep(questionsList),
  });

  const date = moment().format("DD-MM-YYYY HH:mm");
  const scrollView = React.createRef();
  const save = () => {
    console.log("Create Observation", state.questions);
    const { institucion_id, curso_id } = route.params;

    AsyncStorage.getItem("@conectaIdeas:classes").then((response) => {
      const classes = JSON.parse(response);
      const curso = _.find(classes[institucion_id], { curso_id });
      store({
        curso,
        questions: state.questions,
        date,
      });
    });
  };

  const store = async (data) => {
    console.log("Data to store", data);
    const autoInc = "@conectaIdeas:lastObservationId";

    let observationIdPromise;
    if (_.isUndefined(data.key)) {
      observationIdPromise = AsyncStorage.getItem(autoInc).then(
        (lastObservation) => {
          let newId;
          if (_.isNull(lastObservation)) {
            newId = "1";
          } else {
            newId = JSON.stringify(JSON.parse(lastObservation) + 1);
          }
          AsyncStorage.setItem(autoInc, newId);
          console.log("new Id", newId);
          return newId;
        }
      );
    } else {
      observationIdPromise = Promise.resolve(data.key);
    }

    return observationIdPromise.then((observationId) => {
      AsyncStorage.getItem(observationKey)
        .then((evaluation) => {
          if (_.isNull(evaluation)) {
            return {};
          }
          return JSON.parse(evaluation);
        })
        .then((observation) => {
          observation[observationId] = {
            curso: data.curso,
            key: observationId,
            questions: data.questions,
            date: data.date,
          };
          AsyncStorage.setItem(
            observationKey,
            JSON.stringify(observation)
          ).then(() => {
            console.log("DONE ready to move on ObservationScreen");
            navigation.navigate("PendingUpload");
          });
        });
    });
  };

  return (
    <ScrollView ref={scrollView}>
      <View styles={styles.container}>
        {state.questions.map((data, i) => {
          return (
            <RenderQuestion
              key={i}
              data={data}
              questionsList={state.questions}
              setState={setState}
            />
          );
        })}

        <View style={{ height: 20 }} />

        <Button
          style={styles.createQuestion}
          title="Guardar Observación"
          onPress={save}
          testID="CreateObservation"
          disabled={state.buttonDisabled}
        />

        <View style={{ height: 20 }} />
      </View>
    </ScrollView>
  );
}

export const ObservationStorageKey = () => observationKey;
