import { BetterPicker } from "@b9/react-native-ui-components";
import { Picker } from "@react-native-community/picker";
import _ from "lodash";
import React from "react";
import { View } from "react-native";

import Title from "../../UI/Title";

export default function RenderNivel({ state, setNivel }) {
  if (state.oas[state.subsector_id]) {
    return (
      <View>
        <Title title="Nivel" />
        <BetterPicker
          selectedValue={state.nivel}
          onValueChange={setNivel}
          key={state.subsector_id}
        >
          {_.keys(state.oas[state.subsector_id]).map((n) => {
            const i = `${n}`;
            return <Picker.Item label={i} value={n} key={i} />;
          })}
        </BetterPicker>
      </View>
    );
  }
  return null;
}
