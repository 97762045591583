import React from "react";
import { View, FlatList } from "react-native";

import Title from "../UI/Title";
import renderEvaluation from "./renderEvaluation";
import { styles } from "./styles";

export default function ActividadesEspecialesList({
  actividadesEspeciales,
  setRegistros,
  registros,
  setOpenModal,
  setModalProperties,
  navigation,
}) {
  return actividadesEspeciales.length > 0 ? (
    <View style={styles.fullWidth}>
      <Title>Actividades Monitoreo</Title>
      <FlatList
        data={actividadesEspeciales}
        renderItem={(data) =>
          renderEvaluation(
            data,
            setRegistros,
            registros,
            setOpenModal,
            setModalProperties,
            navigation
          )
        }
      />
      <View style={{ height: 20 }} />
    </View>
  ) : null;
}
