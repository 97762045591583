import { BetterPicker } from "@b9/react-native-ui-components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Picker } from "@react-native-community/picker";
import _ from "lodash";
import React, { Component } from "react";
import { View } from "react-native";

import Title from "./Title";

class EjeSelector extends Component {
  constructor(props) {
    super(props);
    const promiseOas = AsyncStorage.getItem("@conectaIdeas:oas").then((oas) =>
      JSON.parse(oas)
    );
    const promiseEjes = AsyncStorage.getItem(
      "@conectaIdeas:ejes"
    ).then((ejes) => JSON.parse(ejes));
    Promise.all([promiseOas, promiseEjes]).then((responses) => {
      const oas = responses[0];
      const ejes = responses[1];
      const eje_id = _.chain(oas[this.props.subsector_id][this.props.nivel])
        .keys()
        .first()
        .value();
      console.log("test2", oas[this.props.subsector_id][this.props.nivel]);
      console.log("eje_id", eje_id);
      this.setState({
        nivel: props.nivel,
        oas,
        ejes,
        eje_id,
      });
    });
  }

  setEje = (eje_id) => {
    this.setState({ eje_id });
    this.props.onSelectEje(eje_id);
  };

  render() {
    const valid = this.state && this.state.oas && this.state.eje_id;
    if (valid) {
      return (
        <View>
          <Title title="Eje" />
          <BetterPicker
            selectedValue={this.state.eje_id}
            onValueChange={this.setEje}
          >
            {_.keys(
              this.state.oas[this.props.subsector_id][this.props.nivel]
            ).map((eje_id) => (
              <Picker.Item
                label={this.state.ejes[eje_id]}
                value={eje_id}
                key={this.state.ejes[eje_id]}
              />
            ))}
          </BetterPicker>
        </View>
      );
    }
    return null;
  }
}

export default EjeSelector;
