import AsyncStorage from "@react-native-async-storage/async-storage";
import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";

import StorageKeys from "./../constants/StorageKeys";
import reloadEvaluations from "./reloadEvaluations";
import { styles } from "./styles";

export default function renderEvaluation(
  data,
  setRegistros,
  registros,
  setOpenModal,
  setModalProperties,
  navigation
) {
  const desc = `${data.item.key}: ${data.item.curso.institucion_nombre} ${data.item.curso.nivel}º ${data.item.curso.nombre} ${data.item.date}`;
  const onPress = () => {
    console.log("OA:", data.item);
    const questionProps = {
      curso_id: data.item.curso.curso_id,
      institucion_id: data.item.curso.institucion_id,
      oa_id: data.item.oa ? data.item.oa.objetivo_aprendizaje_id : undefined,
      key: data.item.key,
      grades: data.item.grades,
      date: data.item.date,
      question: data.item.question,
      tipo: data.item.tipo,
      prehecho_id: data.item.prehecho_id,
      habilidades: data.item.habilidades,
      subsector_id: data.item.subsector_id,
    };

    navigation.navigate("Question", questionProps);
  };

  const deleteMe = () => {
    setModalProperties({
      titleModal: "Confirmar",
      mensajeModal: "¿Deseas eliminar esta evaluación?",
      buttonModalLeft: "No",
      buttonModalRight: "Si, eliminar",
      onPressButtonRight: () =>
        removeEvaluation(data.item.key, setRegistros, setOpenModal),
    });
    setOpenModal(true);
  };

  return (
    <View style={styles.row}>
      <TouchableOpacity style={styles.leftColumn} onPress={onPress}>
        <Text>{desc}</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.rightColumn} onPress={deleteMe}>
        <Icon name="delete" />
      </TouchableOpacity>
    </View>
  );
}

const removeEvaluation = (key, setRegistros, setOpenModal) => {
  AsyncStorage.getItem(StorageKeys.evaluationKey).then((data) => {
    const evaluations = JSON.parse(data);
    delete evaluations[key];
    AsyncStorage.setItem(
      StorageKeys.evaluationKey,
      JSON.stringify(evaluations)
    ).then(() => {
      reloadEvaluations(setRegistros);
    });
  });
  setOpenModal(false);
};
