import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";

import { getTicketPrehechos } from "../../../getTicketPrehechos";

export const setInitialParams = async (initialParams) => {
  const newState = {};
  newState["students"] = await AsyncStorage.getItem(
    `@conectaIdeas:students:${initialParams.curso_id}`
  ).then((response) => {
    const students = JSON.parse(response);
    if (!_.isEmpty(initialParams.grades)) {
      _.each(students, (s) => {
        if (!_.isUndefined(initialParams.grades[s.key])) {
          s.grade = initialParams.grades[s.key];
        }
      });
    }
    return students;
  });
  if (initialParams.oa_id) {
    newState["oa"] = await AsyncStorage.getItem(
      `@conectaIdeas:oas:${initialParams.oa_id}`
    ).then((response) => {
      return JSON.parse(response);
    });
  }
  if (initialParams.prehecho_id) {
    const tickets = getTicketPrehechos();
    const ticket = _.filter(tickets, {
      id: parseInt(initialParams.prehecho_id, 10),
      subsector_id: parseInt(initialParams.subsector_id, 10),
    })[0];
    newState.ticket = ticket;
    newState.prehecho_id = ticket.id;
    newState.subsector_id = ticket.subsector_id;
  }

  const { curso, institucion } = await AsyncStorage.getItem(
    "@conectaIdeas:classes"
  ).then((response) => {
    const classes = JSON.parse(response);

    const curso = _.find(classes[`${initialParams.institucion_id}`], {
      curso_id: `${initialParams.curso_id}`,
    });
    return { curso, institucion: curso.institucion_nombre };
  });

  newState.curso = curso;
  newState.institucion = institucion;
  return newState;
};
