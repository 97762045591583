import { StyleSheet, Dimensions, Platform } from "react-native";

const DEVICE_WIDTH = Dimensions.get("window").width;
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    backgroundColor: "#F5FCFF",
    width: Platform.OS === "web" ? "60%" : "98%",
    marginHorizontal: Platform.OS === "web" ? "20%" : "1%",
    paddingHorizontal: Platform.OS === "web" ? 10 : 5,
  },
  div: {
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    flex: 12,
    flexDirection: "row",
    alignItems: "center",
    borderColor: "#c5c5c5",
    borderWidth: 1,
    padding: 5,
    height: 40,
  },
  leftColumn: {
    flex: 10,
  },
  rightColumn: {
    flex: 2,
  },
  evaluation: {
    width: DEVICE_WIDTH - 40,
  },
  name: {
    flex: 2,
  },
  rightSelected: {
    flex: 1,
    backgroundColor: "blue",
    borderWidth: 1,
    height: 40,
    alignItems: "center",
  },
  rightDeselected: {
    flex: 1,
    borderColor: "blue",
    borderWidth: 1,
    height: 40,
    alignItems: "center",
  },
  wrongSelected: {
    flex: 1,
    backgroundColor: "red",
    borderWidth: 1,
    height: 40,
    alignItems: "center",
  },
  wrongDeselected: {
    flex: 1,
    borderColor: "red",
    borderWidth: 1,
    height: 40,
    alignItems: "center",
  },
  right: {
    color: "blue",
  },
  wrong: {
    color: "red",
  },
  white: {
    color: "white",
  },
  fullWidth: {
    width: "100%",
  },
});
