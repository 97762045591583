import React from "react";
import { Text } from "react-native";

export default function ListObjetivos({ data }) {
  return data.map((obj) => {
    return (
      <Text key={obj.objetivo_aprendizaje_id} style={{ width: "70%" }}>
        {data.length > 1 ? "\n-" : " "}
        {String(obj.numero) + ": " + obj.descripcion_corta}
      </Text>
    );
  });
}
