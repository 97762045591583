import { Auth } from "@aws-amplify/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { authConfig } from "../../../Config";

Auth.configure(authConfig);

class ConectaIdeasClient {
  async getAccessToken() {
    const currentSession = await Auth.currentSession();
    const remainingTime = Math.round(
      currentSession.accessToken.payload.exp - new Date().getTime() / 1000
    );
    console.log("RemainingAuthTime", remainingTime);

    if (!currentSession.isValid()) {
      //refresh token
      throw new Error("Token es inválido");
    }
    return currentSession.getAccessToken().getJwtToken();
  }

  async signIn(username, institutionId, password) {
    //to be compatible with the current authentication scheme
    //here we are coding institutionId as part of the username
    const cognitoUsername = `${institutionId}@@${username.toLowerCase()}`;
    const user = await Auth.signIn(cognitoUsername, password);
    const userData = [];
    userData.push(["@conectaIdeas:username", user.username]);
    userData.push(["userAttributes", JSON.stringify(user.attributes)]);
    await AsyncStorage.multiSet(userData);

    await this.getAccessToken();

    return user;
  }

  async signOut() {
    try {
      await Auth.signOut();
      // dispatch({ type: "SIGN_OUT" });
    } catch (error) {
      console.log("Error cerrar sesión", error);
    }
    try {
      // await clearStorage();
    } catch (error) {
      console.log("Error borrando storage", error);
    }
  }
}

export const getCurrentSession = () => {
  return Auth.currentSession();
};

export default ConectaIdeasClient;
