import React from "react";
import { View, TextInput, Platform } from "react-native";

import Title from "../../UI/Title";
import { styles } from "./styles";

export default function RenderPregunta({
  scrollView,
  setInputFocused,
  onChangeText,
}) {
  const inputFocused = () => {
    if (Platform.OS === "ios") {
      setInputFocused(true);
      setTimeout(() => {
        scrollView.current.scrollToEnd();
      }, 100);
    }
  };

  const inputFocusLost = () => {
    if (Platform.OS === "ios") {
      setInputFocused(false);
    }
  };

  return (
    <View>
      <Title title="Pregunta" />
      <View style={styles.textAreaContainer}>
        <TextInput
          testID="InputQuestion"
          style={styles.textArea}
          underlineColorAndroid="transparent"
          placeholder="Escribe aquí tu pregunta"
          placeholderTextColor="grey"
          numberOfLines={10}
          multiline
          onFocus={inputFocused}
          onBlur={inputFocusLost}
          onChangeText={onChangeText}
          textAlignVertical="top"
        />
      </View>
    </View>
  );
}
