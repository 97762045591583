import { useNavigation, useRoute } from "@react-navigation/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  Platform,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { ListItem } from "react-native-elements";

import Screens from "../constants/Screens";

export default function SecondLoginScreen() {
  const navigation = useNavigation();
  const route = useRoute();

  const [state, setState] = useState({ loading: true });

  useEffect(() => {
    if (typeof route.params.instituciones === "object") {
      setState({
        user: route.params.user,
        instituciones: route.params.instituciones,
        loading: false,
      });
    } else {
      navigation.navigate(Screens.FirstLogin);
    }
  }, [route.params]);

  if (state.loading) {
    return <ActivityIndicator />;
  }
  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={styles.container}>
        <View style={styles.itemContainer}>
          <Text style={styles.text} allowFontScaling={false}>
            {state.user}
          </Text>
        </View>

        <Text style={styles.text} allowFontScaling={false}>
          Selecciona una Institución
        </Text>
        <View style={styles.containerBottom}>
          {state.instituciones.map((inst) => {
            return (
              <ListItem
                key={inst.id}
                onPress={() => {
                  navigation.navigate(Screens.ThirdLogin, {
                    user: state.user,
                    institucion: inst.id,
                  });
                }}
              >
                <ListItem.Content style={styles.buttonList}>
                  <ListItem.Title>{inst.name}</ListItem.Title>
                </ListItem.Content>
              </ListItem>
            );
          })}
        </View>
      </View>
    </ScrollView>
  );
}

const MAX_WIDTH = 800;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  itemContainer: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
  },
  text: { paddingVertical: 3, marginTop: 10, marginBottom: 3, fontSize: 20 },

  buttonList: {
    backgroundColor: "rgb(164,203,244)",
    height: 70,
    padding: 6,
    marginBottom: 4,
    marginHorizontal: 10,
    borderRadius: 3,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.5,
    shadowColor: "rgb(32,96,234)",
    elevation: 5,
    width: "100%",
    paddingLeft: 10,
  },
  containerBottom: {
    width: _.min([MAX_WIDTH, Dimensions.get("window").width * 0.9]),
    minHeight: Platform.OS === "web" ? 10 : "100%",
  },
});
