import { useNavigation } from "@react-navigation/core";
import React from "react";
import { View, Text, TouchableOpacity, FlatList } from "react-native";

import Title from "../UI/Title";
import { styles } from "./styles";

export default function VideosList({ videos }) {
  const navigation = useNavigation();

  const renderVideo = (data) => {
    const tipoShort =
      data.item.tipo.length < 17
        ? data.item.tipo
        : `${data.item.tipo.substr(0, 17)}`;
    const desc = `${data.item.curso.institucion_nombre} ${
      data.item.curso.nivel
    }º ${data.item.curso.nombre}. ${tipoShort}. ${
      data.item.date.split(" ")[0]
    }`;
    const onPress = () => {
      const videoProps = {
        curso_id: data.item.curso.curso_id,
        institucion_id: data.item.curso.institucion_id,
        key: data.item.key,
        date: data.item.date,
        tipo: data.item.tipo,
        videoUrl: data.item.videoUrl,
      };
      navigation.navigate("Video", videoProps);
    };
    return (
      <View style={styles.row}>
        <TouchableOpacity style={styles.leftColumn} onPress={onPress}>
          <Text>{desc}</Text>
        </TouchableOpacity>
      </View>
    );
  };
  return videos.length > 0 ? (
    <View style={styles.fullWidth}>
      <Title>Videos</Title>
      <FlatList data={videos} renderItem={renderVideo} />
      <View style={{ height: 20 }} />
    </View>
  ) : null;
}
