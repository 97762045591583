import { BetterPicker } from "@b9/react-native-ui-components";
import { Picker } from "@react-native-community/picker";
import _ from "lodash";
import React from "react";

import Title from "../../UI/Title";

export default function OptionRenderer({
  selected,
  selectedField,
  dispatch,
  options,
  action,
  title,
  label,
}) {
  if (_.isEmpty(options)) {
    return null;
  }

  const selectedValue = String(
    selectedField ? selected[selectedField] : selected
  );

  return (
    <>
      <Title title={title} />
      <BetterPicker
        selectedValue={String(selectedValue)}
        onValueChange={(optionSelected) =>
          dispatch({
            type: action,
            value: parseInt(String(optionSelected), 10),
          })
        }
      >
        {_.map(options, (option) => {
          if (typeof option == "object") {
            return (
              <Picker.Item
                label={String(option[label])}
                value={String(option.id)}
                key={String(option.id)}
              />
            );
          } else {
            return (
              <Picker.Item
                label={String(option)}
                value={String(option)}
                key={String(option)}
              />
            );
          }
        })}
      </BetterPicker>
    </>
  );
}
