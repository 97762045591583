import { BetterPicker } from "@b9/react-native-ui-components";
import { Picker } from "@react-native-community/picker";
import _ from "lodash";
import React from "react";
import { View } from "react-native";

import Title from "../../UI/Title";

export default function RenderObjetivos({ state, setObjetivoAprendizaje }) {
  return (
    <View>
      <Title title="Objetivo de Aprendizaje" />
      <BetterPicker
        selectedValue={state.oa_id}
        onValueChange={setObjetivoAprendizaje}
        key={state.oa_id}
      >
        {_.map(
          state.oas[state.subsector_id][state.nivel][state.eje_id],
          (v, k) => {
            const s = `${k}`;
            return <Picker.Item label={v.texto} value={k} key={s} />;
          }
        )}
      </BetterPicker>
    </View>
  );
}
