import React from "react";
import { View, Text } from "react-native";

import SumValues from "./SumValues";
import { stylesEvaluation as styles } from "./styles";

export default function RenderTotal({ absolute, grades }) {
  const label = absolute ? "Totales" : "Porcentajes";
  return (
    <View>
      <View style={styles.totals}>
        <Text style={styles.totalsLabel}>{label}</Text>
        <SumValues mode="wrongs" absolute={absolute} grades={grades} />
        <SumValues mode="rights" absolute={absolute} grades={grades} />
      </View>
    </View>
  );
}
