import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";

import { backend } from "../../Config.js";
import { ObservationStorageKey } from "../ObservacionClase/ObservationScreen";
import { VideosStorageKey } from "../Videos/VideoScreen";
import ConectaIdeasClient from "../components/ConectaIdeas/ConectaIdeasClient";

const conectaideas = new ConectaIdeasClient();

export const sendEvaluations = async (evaluations) => {
  console.log("send evaluation", evaluations);
  if (_.isEmpty(evaluations)) {
    return Promise.resolve();
  }
  return conectaideas.getAccessToken().then(async (token) => {
    const api = `${backend}v1/upload_evaluations?accessToken=${token}`;
    return fetch(api, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(evaluations),
    }).then((response) => {
      console.log("send evaluations response", response.status);
      if (response.status === 200) {
        return new Promise((resolve, reject) => {
          resolve("done");
        });
      } else {
        console.log("error evaluations response", JSON.stringify(response));
        return new Promise((resolve, reject) => {
          if (response.status === 401) {
            reject(new Error("Autherror"));
          } else {
            reject(new Error("Error al enviar tickets de salida"));
          }
        });
      }
    });
  });
};

export const sendObservations = async (observations) => {
  console.log("send observations", observations);
  if (_.isEmpty(observations)) {
    return Promise.resolve();
  }

  return conectaideas.getAccessToken().then(async (token) => {
    const api = `${backend}v1/upload_observations?accessToken=${token}`;
    return fetch(api, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(observations),
    }).then((response) => {
      if (response.status === 200) {
        return AsyncStorage.setItem(
          ObservationStorageKey(),
          JSON.stringify({})
        );
      } else {
        return new Promise((resolve, reject) => {
          if (response.status === 401) {
            reject(new Error("Autherror"));
          } else {
            reject(new Error("Error al enviar observaciones"));
          }
        });
      }
    });
  });
};

export const sendVideos = async (videos) => {
  console.log("send videos", videos);
  if (_.isEmpty(videos)) {
    return Promise.resolve();
  }
  return conectaideas.getAccessToken().then(async (token) => {
    const api = `${backend}v1/upload_videos?accessToken=${token}`;
    console.log("Send video", api, videos);
    return fetch(api, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(videos),
    }).then((response) => {
      if (response.status === 200) {
        return AsyncStorage.setItem(VideosStorageKey(), JSON.stringify({}));
      } else {
        return new Promise((resolve, reject) => {
          if (response.status === 401) {
            reject(new Error("Autherror"));
          } else {
            reject(new Error("Error al enviar videos"));
          }
        });
      }
    });
  });
};

export const getErrorMessage = (error) => {
  switch (error) {
    case "Autherror":
      return "Error de Autentificación, por favor ingrese su nueva contraseña.";
    case "Network request failed":
      return "No fue posible enviar, no tienes conexión a internet.";
    case "Failed to fetch":
      return "No fue posible enviar, no tienes conexión a internet.";
    default:
      return `Error: ${error}`;
  }
};
