import { StyleSheet, Platform } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    backgroundColor: "#F5FCFF",
    paddingHorizontal: Platform.OS === "web" ? "30%" : "1%",
  },
  listQuestions: {
    width: Platform.OS === "web" ? "60%" : "98%",
    marginHorizontal: Platform.OS === "web" ? "20%" : "1%",
    paddingHorizontal: Platform.OS === "web" ? 10 : 5,
    height: "90%",
  },
  textAreaContainer: {
    borderColor: "#c5c5c5",
    borderWidth: 1,
    padding: 5,
  },
  textArea: {
    height: 150,
    justifyContent: "flex-start",
  },
  createQuestion: {
    height: 60,
    width: Platform.OS === "web" ? "60%" : "98%",
    marginHorizontal: Platform.OS === "web" ? "20%" : "1%",
  },
});
