import React from "react";
import { Text, View, StyleSheet } from "react-native";

import Title from "../../UI/Title";

export default function RenderObjetivoAprendizaje({ ticket }) {
  if (!ticket) {
    return null;
  }
  return (
    <View>
      <Title>Objetivo(s) de Aprendizaje</Title>
      {ticket.objetivos_aprendizajes.map((obj, index) => {
        return (
          <Text key={obj.objetivo_aprendizaje_id} style={styles.text}>
            <Text style={styles.subText}>{obj.numero}.</Text>{" "}
            {obj.descripcion_larga.replace(/›/g, "\n -")}
          </Text>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    marginVertical: 10,
  },
  subText: {
    fontWeight: "bold",
  },
});
