import { COLOREA } from "../NewOfficialQuestionScreen";

export const createQuestion = ({
  institucionId,
  cursoId,
  navigation,
  ticket,
}) => {
  console.log("TICKET:", ticket);
  let props;
  if (ticket.tipo === COLOREA) {
    const question = `Libro Colorea Ideas Página ${ticket.actividad} Opción ${ticket.opcion}`;

    props = {
      institucion_id: institucionId,
      curso_id: cursoId,
      oa_id: ticket.objetivo_aprendizaje_id,
      prehecho_id: ticket.id,
      tipo: ticket.tipo,
      option: ticket.opcion,
      subsector_id: parseInt(ticket.subsector_id, 10),
      habilidadesId: [],
      question,
    };
  } else {
    const question = `${
      ticket.subsector_id === 7
        ? "Clase " + ticket.clase
        : "Página " + ticket.pagina
    } Tomo ${ticket.tomo} ${ticket.tipo} ${ticket.actividad}`;

    props = {
      institucion_id: institucionId,
      curso_id: cursoId,
      prehecho_id: ticket.id,
      tipo: ticket.tipo,
      option: ticket.opcion,
      subsector_id: parseInt(ticket.subsector_id, 10),
      habilidadesId: [],
      question,
    };
  }
  console.log("createQuestion state", props);
  navigation.navigate("Question", props);
};
