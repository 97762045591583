import { StyleSheet, Platform } from "react-native";

export const stylesEvaluation = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    backgroundColor: "#F5FCFF",
    width: Platform.OS === "web" ? "60%" : "98%",
    marginHorizontal: Platform.OS === "web" ? "20%" : "1%",
    paddingHorizontal: Platform.OS === "web" ? 10 : 5,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  alumno: {
    flex: 2,
  },
  alumnoGoodAnswer: {
    flex: 1,
    color: "blue",
  },
  alumnoBadAnswer: {
    flex: 1,
    color: "red",
  },
  name: {
    flex: 2,
  },
  totalsLabel: {
    flex: 2,
    fontWeight: "bold",
  },
  totals: {
    flexDirection: "row",
  },
  text: {
    fontWeight: "bold",
  },
});

export const stylesStudent = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5FCFF",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    flex: 2,
  },
  baseButton: {
    flex: 1,
    borderWidth: 1,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  baseTextButton: {
    fontWeight: "bold",
  },
  rightSelected: {
    backgroundColor: "blue",
  },
  rightDeselected: {
    borderColor: "blue",
  },
  wrongSelected: {
    backgroundColor: "red",
  },
  wrongDeselected: {
    borderColor: "red",
  },
  right: {
    color: "blue",
  },
  wrong: {
    color: "red",
  },
  white: {
    color: "white",
  },
});

export const stylesSum = StyleSheet.create({
  field: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  right: {
    color: "blue",
  },
  wrong: {
    color: "red",
  },
});
