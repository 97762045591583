import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  Button,
  ActivityIndicator,
} from "react-native";

import { AuthContext } from "../App";
import { ConectaIdeasConfig } from "../Config";
import parameters from "../app.json";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5FCFF",
  },
  version: {
    marginTop: 20,
  },
});

export default function SettingsScreen({ navigation }) {
  const { signOut } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const logout = async () => {
    console.log("logout");
    setLoading(true);
    await signOut();
  };

  const refresh = () => {
    console.log("Refresh");
    AsyncStorage.removeItem("@conectaIdeas:classes").then(() => {
      console.log("a");
      navigation.navigate("Start");
      console.log("b");
    });
  };

  if (loading) {
    return (
      <View style={styles.container}>
        <Text>Cerrando sesión de forma segura</Text>
        <ActivityIndicator
          size="large"
          color="rgb(32,96,234)"
          testID="cerrando"
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text>Año: {ConectaIdeasConfig.year}</Text>
      <Button title="Refrescar lista de cursos y alumnos" onPress={refresh} />
      <Button title="Logout" onPress={logout} />
      <Text style={styles.version}>v. {parameters.expo.version}</Text>
    </View>
  );
}
