// @flow
import PropTypes from "prop-types";
import React, { Component } from "react";
//import { Text } from "react-native-elements";
import { StyleSheet, Text, Platform } from "react-native";

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "bold",
    paddingTop: Platform.OS === "web" ? 10 : 5,
  },
});

class Title extends Component {
  render() {
    return (
      <Text style={styles.title}>
        {this.props.title}
        {this.props.children}
      </Text>
    );
  }
}

Title.propTypes = {
  title: PropTypes.string,
};

export default Title;
