// @flow
import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { View } from "react-native";
import { CheckBox } from "react-native-elements";

import Title from "../../UI/Title";

class HabilidadesSelector extends Component {
  static propTypes = {
    onHabilidadesChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    AsyncStorage.getItem("@conectaIdeas:subsectores")
      .then((subsectores) => JSON.parse(subsectores))
      .then((subsector) => {
        const subsectores = _.keyBy(subsector, "subsector_id");
        this.setState({
          subsector: subsectores,
          subsector_id: this.props.subsector,
        });
      });
  }

  setHabilidad = (habilidad) => (event) => {
    console.log("this.state", this.state);
    const habilidad_id = `${event.target}`;
    console.log("a", typeof this.props.subsector, "e", typeof habilidad_id);
    console.log(
      `this.state.subsector[${this.props.subsector}].habilidades[${habilidad_id}]`
    );
    console.log("habilidad", habilidad);
    habilidad.checked = !habilidad.checked;
    this.setState(this.state);
    this.props.onHabilidadesChange(
      _.filter(this.state.subsector[this.props.subsector].habilidades, {
        checked: true,
      })
    );
  };

  render() {
    if (
      this.state &&
      this.state.subsector &&
      !_.isEmpty(this.state.subsector[this.props.subsector].habilidades)
    ) {
      return (
        <View>
          <Title title="Habilidades" />
          {_.map(
            this.state.subsector[this.props.subsector].habilidades,
            (habilidad) => (
              <CheckBox
                key={habilidad.habilidad_id}
                title={habilidad.nombre}
                onPress={this.setHabilidad(habilidad)}
                checked={habilidad.checked}
              />
            )
          )}
        </View>
      );
    }
    return null;
  }
}

export default HabilidadesSelector;
