import React from "react";
import { View, Text, FlatList } from "react-native";

import Title from "../UI/Title";
import { styles } from "./styles";

export default function ObservacionList({ observations }) {
  const renderObservation = (data) => {
    const desc = `${data.item.key}: ${data.item.curso.institucion_nombre} ${data.item.curso.nivel}º ${data.item.curso.nombre} ${data.item.date}`;

    return (
      <View style={styles.row}>
        <Text style={styles.leftColumn}>{desc}</Text>
      </View>
    );
  };
  return observations.length > 0 ? (
    <View style={styles.fullWidth}>
      <Title>Observaciones de clase</Title>
      <FlatList data={observations} renderItem={renderObservation} />
      <View style={{ height: 20 }} />
    </View>
  ) : null;
}
