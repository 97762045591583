// @flow
import { BetterPicker } from "@b9/react-native-ui-components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Picker } from "@react-native-community/picker";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  Button,
  TouchableHighlight,
  Linking,
  TextInput,
  Platform,
} from "react-native";

import Title from "../UI/Title";
import Modal from "../components/Modal";
import data from "./tipos.json";

const videosStorageKey = "@conectaIdeas:videos";

class VideoScreen extends Component {
  constructor(props) {
    super(props);

    console.log("tipos", data);

    const tipos = _.map(data, "title");

    const {
      curso_id,
      institucion_id,
      key,
      date,
      tipo,
      videoUrl,
    } = this.props.route.params;

    console.log("Tipo_selected", tipo);

    this.state = {
      buttonDisabled: true,
      tipos,
      curso_id,
      institucion_id,
      key,
      videoUrl,
      tipo_selected: tipo === undefined ? tipos[0] : tipo,
      date: date === undefined ? moment().format("DD-MM-YYYY HH:mm") : date,
      isOpenModal: false,
      buttonModalRight: null,
      buttonModalLeft: null,
      titleModal: null,
      mensajeModal: null,
      onPressButtonRight: null,
    };
    console.log("Tipo state", this.state);
    this.scrollView = React.createRef();
  }

  static navigationOptions = {
    headerTitle: "Comunidad de Aprendizaje",
    headerTitleAllowFontScaling: true,
  };

  save = () => {
    console.log("Create Video", this.state);
    const { institucion_id, curso_id } = this.props.route.params;
    if (
      (this.state.videoUrl.includes("youtube.com/") ||
        this.state.videoUrl.includes("youtu.be/")) &&
      this.state.videoUrl.length > 25
    ) {
      AsyncStorage.getItem("@conectaIdeas:classes").then((response) => {
        const classes = JSON.parse(response);
        const curso = _.find(classes[institucion_id], { curso_id });
        this.store({
          curso,
          date: this.state.date,
          tipo: this.state.tipo_selected,
          videoUrl: this.state.videoUrl,
        });
      });
    } else {
      this.setState({
        titleModal: null,
        mensajeModal: "Error: Dirección de video incorrecta",
        buttonModalRight: null,
        buttonModalLeft: "OK",
        isOpenModal: true,
      });
    }
  };

  store = (data) => {
    console.log("Data to store", data);

    const videoId = `${data.curso.curso_id}:${data.tipo}`;

    AsyncStorage.getItem(videosStorageKey)
      .then((evaluation) => {
        if (_.isNull(evaluation)) {
          return {};
        }
        return JSON.parse(evaluation);
      })
      .then((videos) => {
        videos[videoId] = {
          curso: data.curso,
          key: videoId,
          videoUrl: data.videoUrl,
          tipo: data.tipo,
          date: data.date,
        };
        AsyncStorage.setItem(videosStorageKey, JSON.stringify(videos)).then(
          () => {
            console.log("DONE ready to move on VideoScreen");
            this.props.navigation.navigate("PendingUpload");
          }
        );
      });
  };

  setTipo = (tipo_selected) => {
    this.setState({
      tipo_selected,
    });
  };

  setVideo = (videoUrl) => {
    this.setState({
      videoUrl,
    });
  };

  showExample = () => {
    const example = _.find(data, { title: this.state.tipo_selected });
    Linking.openURL(example.example);
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView ref={this.scrollView}>
          <Title title="Actividad" />
          <BetterPicker
            selectedValue={this.state.tipo_selected}
            onValueChange={this.setTipo}
            style={styles.picker}
          >
            {_.map(data, (item) => (
              <Picker.Item
                label={item.title}
                value={item.title}
                key={item.title}
              />
            ))}
          </BetterPicker>
          <Text style={styles.instructions}>
            {_.find(data, { title: this.state.tipo_selected }).instructions}
          </Text>

          <TouchableHighlight onPress={this.showExample}>
            <Text>Ver video de Ejemplo</Text>
          </TouchableHighlight>

          <Title>Youtube</Title>
          <Text style={styles.instructions}>
            Ingrese el link al video en youtube
          </Text>
          <TextInput
            style={styles.input}
            autoFocus
            autoCapitalize={"none"}
            returnKeyType={"done"}
            autoCorrect={false}
            placeholder="https://www.youtube..."
            value={this.state.videoUrl}
            onChangeText={this.setVideo}
            testID="videoField"
          />

          <View style={{ height: 20 }} />
          <Button
            style={styles.createQuestion}
            title="Guardar"
            onPress={this.save}
            testID="CreateVideo"
            disabled={
              this.state.videoUrl === undefined ||
              this.state.videoUrl.length < 10
            }
          />
          <View style={{ height: 20 }} />
          {this.state.isOpenModal && (
            <Modal
              title={this.state.titleModal}
              mensaje={this.state.mensajeModal}
              buttonRight={this.state.buttonModalRight}
              buttonLeft={this.state.buttonModalLeft}
              onPressButtonRight={this.state.onPressButtonRight}
              onPressButtonLeft={() => this.setState({ isOpenModal: false })}
            />
          )}
        </ScrollView>
      </View>
    );
  }
}

export default VideoScreen;

export const VideosStorageKey = () => videosStorageKey;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    backgroundColor: "#F5FCFF",
    paddingHorizontal: Platform.OS === "web" ? "30%" : "1%",
  },
  textAreaContainer: {
    borderColor: "#c5c5c5",
    borderWidth: 1,
    padding: 5,
  },
  textArea: {
    height: 150,
    justifyContent: "flex-start",
  },
  createQuestion: {
    height: 60,
  },
  instructions: {
    margin: 10,
  },
  input: {
    color: "#000000",
  },
  picker: {
    width: "98%",
  },
});
