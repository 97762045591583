const ENDPOINT =
  "https://j4vltickb2.execute-api.us-east-1.amazonaws.com/institutions";

export const getInstitutionsList = async (username) => {
  const remotePath = `${ENDPOINT}/Chile/${username}`;

  return await fetch(remotePath)
    .then((response) => {
      return response.json();
    })
    .catch(() => {
      return [];
    });
};
