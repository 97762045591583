// @flow
import { useNavigation, useRoute } from "@react-navigation/core";
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { Button, ScrollView, View, Text, Platform } from "react-native";

import EjeSelector from "../../UI/EjeSelector";
import Modal from "../../components/Modal";
import HabilidadesSelector from "./HabilidadesSelector";
import RenderNivel from "./RenderNivel";
import RenderObjetivos from "./RenderObjetivos";
import RenderPregunta from "./RenderPregunta";
import RenderSubsector from "./RenderSubsector";
import { computeState } from "./functions/computeState";
import { setInitialParams } from "./functions/setInitialParams";
import { styles } from "./styles";

export default function NewQuestionScreen() {
  const navigation = useNavigation();
  const route = useRoute();

  const scrollView = useRef();

  const [modalProperties, setModalProperties] = useState({
    buttonModalRight: null,
    buttonModalLeft: null,
    titleModal: null,
    mensajeModal: null,
    onPressButtonRight: null,
    isOpenModal: false,
    error: "",
  });
  const [state, setState] = useState({ loaded: false });
  const [isInputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    setInitialParams(route, setState, setModalProperties);
  }, []);

  const createQuestion = () => {
    console.log("createQuestion state", state);
    const props = {
      institucion_id: state.institucion_id,
      curso_id: state.curso_id,
      oa_id: state.oa_id,
      habilidadesId: state.habilidadesId,
      question: state.text,
    };
    navigation.navigate("Question", props);
  };

  const setNivel = (nivel) => {
    computeState("nivel", nivel, state, setState);
  };

  const setSubsector = (subsectorId) => {
    computeState("subsector_id", subsectorId, state, setState);
  };

  const selectEje = (ejeId) => {
    console.log("setEje", ejeId);
    computeState("eje_id", ejeId, state, setState);
  };

  const setObjetivoAprendizaje = (oa_id) => {
    setState({ ...state, oa_id });
  };

  const setHabilidades = (event) => {
    console.log("habilidades", event);
    setState({ ...state, habilidadesId: _.map(event, "habilidad_id") });
  };

  const offset = () => {
    if (Platform.OS === "ios") {
      return <View style={{ height: isInputFocused ? 360 : 1 }} />;
    }
    return <View style={{ height: 20 }} />;
  };

  if (!state.loaded || _.isUndefined(state.subsectores)) {
    return (
      <View>
        <Text>Cargando</Text>
        <Text>{modalProperties.error}</Text>
      </View>
    );
  }
  return (
    <ScrollView styles={styles.container} ref={scrollView}>
      <View style={styles.subContainer}>
        <RenderSubsector state={state} setSubsector={setSubsector} />
        <RenderNivel state={state} setNivel={setNivel} />
        <EjeSelector
          subsector_id={state.subsector_id}
          nivel={state.nivel}
          onSelectEje={selectEje}
          key={state.eje_id}
        />
        <RenderObjetivos
          state={state}
          setObjetivoAprendizaje={setObjetivoAprendizaje}
        />

        <HabilidadesSelector
          subsector={state.subsector_id}
          onHabilidadesChange={setHabilidades}
        />
        <RenderPregunta
          scrollView={scrollView}
          setInputFocused={setInputFocused}
          onChangeText={(text) => setState({ ...state, text })}
        />

        <Button
          style={styles.createQuestion}
          title="Crear pregunta"
          onPress={createQuestion}
          testID="CreateQuestion"
          disabled={
            _.isUndefined(state.oa_id) ||
            _.isUndefined(state.text) ||
            state.text.size < 1
          }
        />
        {offset()}
        {modalProperties.isOpenModal && (
          <Modal
            title={modalProperties.titleModal}
            mensaje={modalProperties.mensajeModal}
            buttonRight={modalProperties.buttonModalRight}
            buttonLeft={modalProperties.buttonModalLeft}
            onPressButtonRight={modalProperties.onPressButtonRight}
            onPressButtonLeft={() =>
              setModalProperties({ ...modalProperties, isOpenModal: false })
            }
          />
        )}
      </View>
    </ScrollView>
  );
}
