// @flow
import AsyncStorage from "@react-native-async-storage/async-storage";
import NetInfo from "@react-native-community/netinfo";
import { useNavigation } from "@react-navigation/core";
import { useIsFocused } from "@react-navigation/native";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet, ActivityIndicator } from "react-native";

import { AuthContext } from "../App";
import { backend, ConectaIdeasConfig } from "../Config";
import {
  ClassesKey,
  InstitutionsKey,
} from "./ClassSelection/ClassSelectionScreen";
import { ObservationStorageKey } from "./ObservacionClase/ObservationScreen";
import { VideosStorageKey } from "./Videos/VideoScreen";
import ConectaIdeasClient from "./components/ConectaIdeas/ConectaIdeasClient";
import Modal from "./components/Modal";
import StorageKeys from "./constants/StorageKeys";

const year = ConectaIdeasConfig.year;

export default function StartScreen() {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const conectaideas = new ConectaIdeasClient();
  const { signOut } = useContext(AuthContext);
  const [state, setState] = useState({
    isOpenModal: false,
    buttonModalRight: null,
    buttonModalLeft: null,
    titleModal: null,
    mensajeModal: null,
    onPressButtonRight: null,
  });

  const start = () => {
    const evaluationPromise = AsyncStorage.getItem(StorageKeys.evaluationKey);
    const observationPromise = AsyncStorage.getItem(ObservationStorageKey());
    const classesPromise = AsyncStorage.getItem(ClassesKey());
    const videosPromise = AsyncStorage.getItem(VideosStorageKey());

    Promise.all([
      evaluationPromise,
      observationPromise,
      classesPromise,
      videosPromise,
    ]).then((data) => {
      const evaluation = data[0];
      const observation = data[1];
      const classes = data[2];
      const videos = data[3];
      if (classes === "{}") {
        setState({
          titleModal: null,
          mensajeModal: "Error: Profesor no posee cursos asignados",
          buttonModalRight: null,
          buttonModalLeft: "OK",
          isOpenModal: true,
          onPressButtonLeft: () => backToLogin(),
        });
      } else {
        if (
          (_.isNull(evaluation) || _.isEmpty(JSON.parse(evaluation))) &&
          (_.isNull(observation) || _.isEmpty(JSON.parse(observation))) &&
          (_.isNull(videos) || _.isEmpty(JSON.parse(videos)))
        ) {
          navigation.navigate("ClassSelection");
        } else {
          navigation.navigate("PendingUpload");
        }
      }
    });
  };

  useEffect(() => {
    if (isFocused) {
      conectaideas.getAccessToken().then((auth) => {
        if (_.isNull(auth)) {
          backToLogin();
        } else {
          const institutionsPromise = AsyncStorage.getItem(InstitutionsKey());
          const classesPromise = AsyncStorage.getItem(ClassesKey());

          Promise.all([institutionsPromise, classesPromise]).then((data) => {
            const institutions = data[0];
            const classes = data[1];
            if (
              _.isNull(classes) ||
              classes === "{}" ||
              _.isNull(institutions) ||
              institutions === "{}"
            ) {
              tryToRefreshData(auth).then(() => {
                start();
              });
            } else {
              start();
            }
          });
        }
      });
    }
  }, [isFocused]);

  const backToLogin = async () => {
    await signOut();
  };
  const tryToRefreshData = async (auth) => {
    return NetInfo.fetch().then(async (state) => {
      if (state.isConnected) {
        return refreshData(auth).then(() => {
          return Promise.resolve();
        });
      }
    });
  };

  const parseInstitutionAndClasses = (data) => {
    const byGroup = _.groupBy(data, "institucion_id");
    const institution = _.chain(data)
      .map((row) => [row.institucion_id, row.institucion_nombre])
      .uniqBy((r) => r[0])
      .sortBy((r) => r[1])
      .value();
    return {
      institutions: institution,
      classes: byGroup,
    };
  };

  const refreshData = async (token) => {
    console.log("Refreshing");

    const listClasses = `${backend}v1/list_classes?year=${year}&accessToken=${token}`;
    const listStudents = `${backend}v1/list_students?year=${year}&accessToken=${token}`;
    const listOas = `${backend}v1/list_oas?accessToken=${token}`;
    const listSubsectors = `${backend}v1/list_subsector?accessToken=${token}`;
    console.log("listClasses endpoint", listClasses);
    console.log("listStudents endpoint", listStudents);
    console.log("listOas endpoint", listOas);

    const p1 = fetch(listClasses)
      .then((response) => {
        console.log("raw response", response.status, response);
        return response.json();
      })
      .then(async (data) => {
        const { institutions, classes } = parseInstitutionAndClasses(data);
        console.log("i", institutions);
        console.log("c", classes);
        const ap1 = AsyncStorage.setItem(
          InstitutionsKey(),
          JSON.stringify(institutions)
        );
        const ap2 = AsyncStorage.setItem(ClassesKey(), JSON.stringify(classes));

        return Promise.all([ap1, ap2]).then(() => {
          console.log("institutions and classes refreshed on DB");
        });
      })
      .catch((error) => console.log(error));

    const p2 = fetch(listStudents)
      .then((response) => {
        console.log("RESPONSE FETCH listStudents:", response.status);
        return response.json();
      })
      .then((data) => {
        const promises = [];
        _.forEach(data, (row) => {
          const key = `@conectaIdeas:students:${row.curso_id}`;
          promises.push(AsyncStorage.setItem(key, JSON.stringify(row.alumnos)));
        });
        return Promise.all(promises);
      });

    const subsectorsPromise = fetch(listSubsectors).then((response) =>
      response.json()
    );

    const oasPromise = fetch(listOas).then((response) => response.json());

    const p3 = Promise.all([oasPromise, subsectorsPromise]).then((response) => {
      const allOas = response[0];
      const allSubsectors = response[1];
      const validSubsectors = [];
      const oas = {};
      const ejes = {};
      const promises = [];

      const oasById = [];
      _.forEach(allOas, (oa) => {
        if (_.isUndefined(oas[oa.subsector_id])) {
          oas[oa.subsector_id] = {};
          validSubsectors.push(
            _.find(allSubsectors, { subsector_id: oa.subsector_id })
          );
        }
        if (_.isUndefined(oas[oa.subsector_id][oa.nivel])) {
          oas[oa.subsector_id][oa.nivel] = {};
        }
        if (_.isUndefined(oas[oa.subsector_id][oa.nivel][oa.eje_id])) {
          ejes[oa.eje_id] = oa.eje;
          oas[oa.subsector_id][oa.nivel][oa.eje_id] = {};
        }
        oas[oa.subsector_id][oa.nivel][oa.eje_id][
          oa.objetivo_aprendizaje_id
        ] = oa;

        oasById.push([
          `@conectaIdeas:oas:${oa.objetivo_aprendizaje_id}`,
          JSON.stringify(oa),
        ]);
      });
      AsyncStorage.multiSet(oasById, () => {
        console.log("multi insert done");
      });

      const oasKey = "@conectaIdeas:oas";
      promises.push(AsyncStorage.setItem(oasKey, JSON.stringify(oas)));

      const ejesKey = "@conectaIdeas:ejes";
      promises.push(AsyncStorage.setItem(ejesKey, JSON.stringify(ejes)));

      const subsectoresKey = "@conectaIdeas:subsectores";
      promises.push(
        AsyncStorage.setItem(subsectoresKey, JSON.stringify(validSubsectors))
      );

      console.log("oas on DB", oas);
      return Promise.all(promises);
    });

    console.log("Done with refresh");
    return Promise.all([p1, p2, p3]).catch((exception) => {
      console.log("capture exception", exception.message);

      const error =
        "Error: Usuario no posee permisos de profesor E3 " + exception.message;
      console.log(error, exception);
      setState({
        titleModal: null,
        mensajeModal: error,
        buttonModalRight: null,
        buttonModalLeft: "OK",
        isOpenModal: true,
        onPressButtonLeft: () => backToLogin(),
      });
    });
  };

  return (
    <View style={styles.container}>
      <Text>Descargando lista de alumnos</Text>
      <ActivityIndicator size="small" color="#0000ff" />
      {state.isOpenModal && (
        <Modal
          title={state.titleModal}
          mensaje={state.mensajeModal}
          buttonRight={state.buttonModalRight}
          buttonLeft={state.buttonModalLeft}
          onPressButtonRight={state.onPressButtonRight}
          onPressButtonLeft={state.onPressButtonLeft}
        />
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5FCFF",
  },
});
