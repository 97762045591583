import PropTypes from "prop-types";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  Platform,
} from "react-native";

export default function Modal(props) {
  const styles = StyleSheet.create({
    modalContainer: {
      flex: 1,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(128,128,128,0.8)",
    },
    modalContent: {
      width: Dimensions.get("window").width < 400 ? "90%" : 400,
      height: props.mensaje.length > 70 ? 300 : 180,
      backgroundColor: "white",
      borderColor: "rgba(19,120,215,1)",
      borderRadius: 8,
      borderWidth: 2,
      alignItems: "center",
      shadowOffset: { width: 5, height: 5 },
      shadowOpacity: 0.3,
      shadowColor: "rgba(19,120,215,1)",
      flexDirection: "column",
      paddingHorizontal: 3,
      paddingTop: 8,
    },
    titleContainer: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      height: "60%",
      width: "100%",
      paddingTop: 1,
      paddingHorizontal: 10,
    },
    mensajeContainer: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      height: "60%",
      width: "100%",
      paddingTop: 1,
      paddingHorizontal: 10,
    },
    modalTitle: {
      fontSize: Platform.OS === "ios" ? 28 : 30,
      justifyContent: "center",
      alignItems: "center",
      height: "18%",
    },
    subText: {
      fontSize: Platform.OS === "ios" ? 18 : 20,
      textAlign: "center",
    },
    containerButtons: {
      alignItems: "center",
      justifyContent: props.buttonRight ? "space-between" : "center",
      flexDirection: "row",
      width: "100%",
      height: "25%",
      bottom: 0,
      position: "absolute",
      paddingHorizontal: 10,
    },
    button: {
      backgroundColor: "rgba(19,120,215,1)",
      height: 30,
      width: "40%",
      borderRadius: 3,
      borderColor: "rgba(19,120,215,1)",
      borderWidth: 1,
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.2,
      shadowColor: "rgba(19,120,215,1)",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      opacity: 1,
      elevation: 5,
    },
    textButton: {
      fontSize: 20,
      color: "white",
    },
  });
  return (
    <View style={styles.modalContainer}>
      <View style={styles.modalContent}>
        {props.title && (
          <Text style={styles.modalTitle} allowFontScaling={false}>
            {props.title}
          </Text>
        )}
        {props.mensaje && (
          <View style={styles.mensajeContainer}>
            <Text style={styles.subText} allowFontScaling={false}>
              {props.mensaje}
            </Text>
          </View>
        )}

        <View style={styles.containerButtons}>
          {props.buttonLeft && (
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                props.onPressButtonLeft(false);
              }}
            >
              <Text style={styles.textButton}>{props.buttonLeft} </Text>
            </TouchableOpacity>
          )}
          {props.buttonRight && (
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                props.onPressButtonRight();
              }}
            >
              <Text style={styles.textButton}>{props.buttonRight}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
}

Modal.propTypes = {
  onPressButtonLeft: PropTypes.func,
  onPressButtonRight: PropTypes.func,
  title: PropTypes.string,
  mensaje: PropTypes.string,
};
