import { BetterPicker } from "@b9/react-native-ui-components";
import { Picker } from "@react-native-community/picker";
import _ from "lodash";
import React from "react";
import { View } from "react-native";

import Title from "../../UI/Title";

export default function RenderSubsector({ state, setSubsector }) {
  return (
    <View>
      <Title title="Subsector" />
      <BetterPicker
        selectedValue={state.subsector_id}
        onValueChange={setSubsector}
      >
        {_.map(state.subsectores, (subsector) => (
          <Picker.Item
            label={subsector.subsector}
            value={subsector.subsector_id}
            key={subsector.subsector_id}
          />
        ))}
      </BetterPicker>
    </View>
  );
}
