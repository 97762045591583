import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    backgroundColor: "#F5FCFF",
    alignItems: "center",
  },
  subContainer: {
    flexDirection: "column",
    width: Platform.OS === "web" ? "40%" : "98%",
    marginHorizontal: Platform.OS === "web" ? "30%" : "1%",
    paddingHorizontal: Platform.OS === "web" ? 15 : 5,
  },
  textAreaContainer: {
    borderColor: "#c5c5c5",
    borderWidth: 1,
    padding: 5,
  },
  textArea: {
    height: 150,
    justifyContent: "flex-start",
  },
  createQuestion: {
    height: 60,
  },
  input: {
    borderColor: "#c5c5c5",
    borderWidth: 1,
    height: 40,
    margin: 20,
    paddingLeft: 10,
    color: "#000000",
  },
  instructions: {
    margin: 10,
  },
});
