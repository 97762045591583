import * as Linking from "expo-linking";

export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      PendingUpload: "registros",
      ClassSelection: "inicio",
      NewQuestion: "ticket_salida",
      NewOfficialQuestion: "actividad/ticket_oficial",
      Observation: "observacion",
      Video: "video",
      Question: "actividad/evaluacion",

      Start: "cargando",
      FirstLogin: "login",
      SecondLogin: "login/institucion",
      ThirdLogin: {
        path: "login/contraseña/:institucion/:user",
        parse: {
          user: String,
          institucion: Number,
        },
      },
    },
  },
};
