import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import FirstLoginScreen from "../Login/FirstLoginScreen";
import SecondLoginScreen from "../Login/SecondLoginScreen";
import ThirdLoginScreen from "../Login/ThirdLoginScreen";
import Screens from "../constants/Screens";

const Auth = createStackNavigator();

export default function LoginStackNavigator() {
  return (
    <Auth.Navigator>
      <Auth.Screen
        name={Screens.FirstLogin}
        component={FirstLoginScreen}
        options={{ title: "Bienvenido a ConectaIdeasExpress" }}
      />
      <Auth.Screen
        name={Screens.SecondLogin}
        component={SecondLoginScreen}
        options={{ title: "Bienvenido a ConectaIdeasExpress" }}
      />
      <Auth.Screen
        name={Screens.ThirdLogin}
        component={ThirdLoginScreen}
        options={{ title: "Bienvenido a ConectaIdeasExpress" }}
      />
    </Auth.Navigator>
  );
}
