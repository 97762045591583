import { useNavigation, useRoute } from "@react-navigation/core";
import _ from "lodash";
import React, { useEffect, useReducer } from "react";
import { Button, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import { getTicketPrehechos } from "../../getTicketPrehechos";
import OptionRenderer from "./OptionRenderer";
import RenderObjetivoAprendizaje from "./RenderObjetivoAprendizaje";
import { createQuestion } from "./functions/createQuestion";
import { styles } from "./styles";

export const COLOREA = "Libro Colorea Ideas";

export const INITIAL_LOAD = "INITIAL_LOAD";
export const SET_SUBSECTOR = "SET_SUBSECTOR";
export const SET_TOMO = "SET_TOMO";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_CLASS_NUMBER = "SET_CLASS_NUMBER";
export const SET_TICKET_NUMBER = "SET_TICKET_NUMBER";
export const UPDATE = "UPDATE";

export function activityReducer(state, action) {
  switch (action.type) {
    case INITIAL_LOAD: {
      const tipos = _.chain(action.value).map("tipo").uniq().value();
      const subsectoresChoices = _.chain(action.value)
        .map((d) => {
          return {
            id: d.subsector_id,
            subsector: d.subsector,
          };
        })
        .uniqBy("id")
        .value();

      const subsectorIdSelected = subsectoresChoices[0].id;

      const tomosChoices = _.chain(action.value)
        .filter({ nivel: state.nivel, subsector_id: subsectorIdSelected })
        .map("tomo")
        .uniq()
        .value();

      const tomoSelected = tomosChoices[0];

      const pageChoices = _.chain(action.value)
        .filter({
          nivel: state.nivel,
          subsector_id: subsectorIdSelected,
          tomo: tomoSelected,
        })
        .map("pagina")
        .uniq()
        .value();

      const pageSelected = pageChoices[0];

      const ticketChoices = _.chain(action.value)
        .filter({
          nivel: state.nivel,
          subsector_id: subsectorIdSelected,
          tomo: tomoSelected,
          pagina: pageSelected,
        })
        .value();

      const ticketSelected = ticketChoices[0];

      return {
        ...state,
        allTickets: action.value,
        tiposChoices: tipos,
        subsectoresChoices,

        activityType: tipos[0],
        subsectorIdSelected,

        tomosChoices,
        tomoSelected,
        pageChoices,
        pageSelected,
        ticketChoices,
        ticketSelected,

        ready: true,
      };
    }

    case SET_SUBSECTOR: {
      const subsectorIdSelected = action.value;
      const tomosChoices = _.chain(state.allTickets)
        .filter({ nivel: state.nivel, subsector_id: subsectorIdSelected })
        .map("tomo")
        .uniq()
        .value();

      const tomoSelected = tomosChoices[0];

      const pageChoices = _.chain(state.allTickets)
        .filter({
          nivel: state.nivel,
          subsector_id: subsectorIdSelected,
          tomo: tomoSelected,
        })
        .map("pagina")
        .filter((d) => {
          return d;
        })
        .uniq()
        .value();
      const pageSelected = pageChoices[0];

      const claseChoices = _.chain(state.allTickets)
        .filter({
          nivel: state.nivel,
          subsector_id: subsectorIdSelected,
          tomo: tomoSelected,
        })
        .map("clase")
        .filter((d) => {
          return d;
        })
        .uniq()
        .value();

      const claseSelected = claseChoices[0];

      const ticketFilter = {
        nivel: state.nivel,
        subsector_id: subsectorIdSelected,
        tomo: tomoSelected,
      };

      if (claseSelected) {
        ticketFilter["clase"] = claseSelected;
      }

      if (pageSelected) {
        ticketFilter["pagina"] = pageSelected;
      }

      const ticketChoices = _.chain(state.allTickets)
        .filter(ticketFilter)
        .value();

      const ticketSelected = ticketChoices[0];

      return {
        ...state,
        subsectorIdSelected,
        tomosChoices,
        tomoSelected,
        pageChoices,
        pageSelected,
        claseChoices,
        claseSelected,
        ticketChoices,
        ticketSelected,
      };
    }

    case SET_TOMO: {
      const tomoSelected = action.value;
      const pageChoices = _.chain(state.allTickets)
        .filter({
          nivel: state.nivel,
          subsector_id: state.subsectorIdSelected,
          tomo: tomoSelected,
        })
        .map("pagina")
        .filter((d) => {
          return d;
        })
        .uniq()
        .value();
      const pageSelected = pageChoices[0];

      const claseChoices = _.chain(state.allTickets)
        .filter({
          nivel: state.nivel,
          subsector_id: state.subsectorIdSelected,
          tomo: tomoSelected,
        })
        .map("clase")
        .filter((d) => {
          return d;
        })
        .uniq()
        .value();

      const claseSelected = claseChoices[0];

      const ticketFilter = {
        nivel: state.nivel,
        subsector_id: state.subsectorIdSelected,
        tomo: tomoSelected,
      };

      if (claseSelected) {
        ticketFilter["clase"] = claseSelected;
      }

      if (pageSelected) {
        ticketFilter["pagina"] = pageSelected;
      }

      const ticketChoices = _.chain(state.allTickets)
        .filter(ticketFilter)
        .value();

      const ticketSelected = ticketChoices[0];

      return {
        ...state,
        tomoSelected,
        pageChoices,
        pageSelected,
        claseChoices,
        claseSelected,
        ticketChoices,
        ticketSelected,
      };
    }

    case SET_PAGE_NUMBER: {
      const pageSelected = action.value;
      const ticketFilter = {
        nivel: state.nivel,
        subsector_id: state.subsectorIdSelected,
        tomo: state.tomoSelected,
        pagina: pageSelected,
      };

      const ticketChoices = _.chain(state.allTickets)
        .filter(ticketFilter)
        .value();

      const ticketSelected = ticketChoices[0];

      return {
        ...state,
        pageSelected,
        ticketChoices,
        ticketSelected,
      };
    }

    case SET_CLASS_NUMBER: {
      const claseSelected = action.value;
      const ticketFilter = {
        nivel: state.nivel,
        subsector_id: state.subsectorIdSelected,
        tomo: state.tomoSelected,
        clase: claseSelected,
      };

      const ticketChoices = _.chain(state.allTickets)
        .filter(ticketFilter)
        .value();

      const ticketSelected = ticketChoices[0];

      return {
        ...state,
        claseSelected,
        ticketChoices,
        ticketSelected,
      };
    }

    case SET_TICKET_NUMBER: {
      const ticket = _.chain(state.allTickets)
        .filter({ id: action.value })
        .value();
      const ticketSelected = ticket[0];
      return {
        ...state,
        ticketSelected,
      };
    }

    default:
      throw new Error("Invalid action:" + JSON.stringify(action));
  }
}

export function initialStatus(routeParams) {
  const nivel = parseInt(routeParams.nivel, 10);
  const institucionId = parseInt(routeParams.institucion_id, 10);
  const cursoId = parseInt(routeParams.curso_id, 10);
  return {
    ready: false,
    nivel,
    institucionId,
    cursoId,

    //available choices
    tiposChoices: [],
    subsectoresChoices: [],
    tomosChoices: [],
    pageChoices: [],
    claseChoices: [],

    //selection
    activityType: undefined,
    subsectorIdSelected: undefined,
    tomoSelected: undefined,
    pageSelected: undefined,
    claseSelected: undefined,
    ticketSelected: undefined,
  };
}

export default function NewOfficialQuestionScreen() {
  const route = useRoute();
  const navigation = useNavigation();

  const scrollView = React.createRef();

  const [state, dispatch] = useReducer(
    activityReducer,
    initialStatus(route.params)
  );

  useEffect(() => {
    dispatch({ type: INITIAL_LOAD, value: getTicketPrehechos() });
  }, []);

  if (!state.ready) {
    return null;
  }

  return (
    <ScrollView styles={styles.container} ref={scrollView}>
      <View style={styles.subContainer}>
        {/*<Title title="Tipo de Actividad"/>*/}
        {/*<BetterPicker*/}
        {/*  selectedValue={state.activityType}*/}
        {/*  onValueChange={*/}
        {/*    (tipo) => dispatch({ type: 'activityType', value: tipo })*/}
        {/*  }*/}
        {/*>*/}
        {/*  {_.map(state.tiposChoices, (tipo) => (*/}
        {/*    <Picker.Item label={tipo} value={tipo} key={tipo}/>*/}
        {/*  ))}*/}
        {/*</BetterPicker>*/}

        <OptionRenderer
          title="Subsector"
          action={SET_SUBSECTOR}
          options={state.subsectoresChoices}
          selected={state.subsectorIdSelected}
          dispatch={dispatch}
          label={"subsector"}
          testID={"subSectorSelector"}
        />

        <OptionRenderer
          title="Tomo"
          action={SET_TOMO}
          options={state.tomosChoices}
          selected={state.tomoSelected}
          dispatch={dispatch}
          testID={"tomoSelector"}
          key={`${state.subsectorIdSelected}`}
        />

        <OptionRenderer
          title="Número de página"
          action={SET_PAGE_NUMBER}
          options={state.pageChoices}
          selected={state.pageSelected}
          dispatch={dispatch}
          testID="pageSelector"
          key={`${state.subsectorIdSelected}:${state.tomoSelected}`}
        />

        <OptionRenderer
          title="Número de clase"
          action={SET_CLASS_NUMBER}
          options={state.claseChoices}
          selected={state.claseSelected}
          dispatch={dispatch}
          testID="claseSelector"
          key={`${state.subsectorIdSelected}:${state.tomoSelected}:${state.pageSelected}`}
        />

        <OptionRenderer
          title="Número de ticket"
          action={SET_TICKET_NUMBER}
          options={state.ticketChoices}
          selected={state.ticketSelected}
          selectedField={"id"}
          dispatch={dispatch}
          label={"actividad"}
          testID="ticketSelector"
          key={`${state.subsectorIdSelected}:${state.tomoSelected}:${state.pageSelected}:${state.claseSelected}`}
        />

        <RenderObjetivoAprendizaje ticket={state.ticketSelected} />

        <Button
          style={styles.createQuestion}
          title="Continuar"
          onPress={() => {
            createQuestion({
              institucionId: state.institucionId,
              cursoId: state.cursoId,
              ticket: state.ticketSelected,
              navigation,
            });
          }}
          testID="CreateQuestion"
          disabled={state.ticketSelected === undefined}
        />
      </View>
    </ScrollView>
  );
}
