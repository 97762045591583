import _ from "lodash";
import React from "react";
import { View, Text } from "react-native";

import { stylesSum as styles } from "./styles";

export default function SumValues({ mode, absolute, grades }) {
  const getStyle = () => {
    if (mode === "wrongs") {
      return styles.wrong;
    }
    return styles.right;
  };

  const getLabel = (rights, wrongs, totals) => {
    if (absolute) {
      if (mode === "wrongs") {
        return `${wrongs} / ${totals}`;
      }
      return `${rights} / ${totals}`;
    }
    if (totals === 0) {
      return "- %";
    }
    let sum;
    if (mode === "wrongs") {
      sum = wrongs;
    } else {
      sum = rights;
    }
    const p = Math.round((sum / totals) * 100);
    return `${p} %`;
  };

  const rights = _.chain(grades)
    .values()
    .filter((x) => x > 0)
    .sum()
    .value();

  const wrongs =
    _.chain(grades)
      .values()
      .filter((x) => x < 0)
      .sum()
      .value() * -1;

  const totals = rights + wrongs;

  return (
    <View style={styles.field}>
      <Text style={getStyle()}>{getLabel(rights, wrongs, totals)} </Text>
    </View>
  );
}
