import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";

import StorageKeys from "../../../constants/StorageKeys";

export const store = (data) => {
  console.log("Data to store", data);
  const autoInc = "@conectaIdeas:lastEvaluationId";

  let evaluationIdPromise;
  if (_.isUndefined(data.key)) {
    evaluationIdPromise = AsyncStorage.getItem(autoInc).then(
      (lastEvaluation) => {
        let newId;
        if (_.isNull(lastEvaluation)) {
          newId = "1";
        } else {
          newId = JSON.stringify(JSON.parse(lastEvaluation) + 1);
        }
        AsyncStorage.setItem(autoInc, newId);
        console.log("new Id", newId);
        return newId;
      }
    );
  } else {
    evaluationIdPromise = Promise.resolve(data.key);
  }

  return evaluationIdPromise.then((evaluationId) => {
    return AsyncStorage.getItem(StorageKeys.evaluationKey)
      .then((evaluation) => {
        if (_.isNull(evaluation)) {
          return {};
        }
        return JSON.parse(evaluation);
      })
      .then((evaluation) => {
        evaluation[evaluationId] = {
          curso: data.curso,
          date: data.date,
          grades: data.grades,
          oa: data.oa,
          key: evaluationId,
          habilidades: data.habilidades,
          question: data.question,
          tipo: data.tipo,
          prehecho_id: data.prehecho_id,
          subsector_id: data.subsector_id,
        };
        return AsyncStorage.setItem(
          StorageKeys.evaluationKey,
          JSON.stringify(evaluation)
        ).then(() => {
          return "DONE!";
        });
      });
  });
};
