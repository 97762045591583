import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import ClassSelectionScreen from "../ClassSelection/ClassSelectionScreen";
import ObservationScreen from "../ObservacionClase/ObservationScreen";
import PendingUploadScreen from "../PendingUpload/PendingUploadScreen";
import SettingsScreen from "../SettingsScreen";
import StartScreen from "../StartScreen";
import EvaluationScreen from "../TicketSalida/Evaluation/EvaluationScreen";
import NewOfficialQuestionScreen from "../TicketSalida/NewOfficialQuestion/NewOfficialQuestionScreen";
import NewQuestionScreen from "../TicketSalida/NewQuestion/NewQuestionScreen";
import VideoScreen from "../Videos/VideoScreen";
import TopRightButton from "../components/TopRightButton";

const Stack = createStackNavigator();
export default function AppStackNavigator() {
  return (
    <Stack.Navigator
      screenOptions={({ navigation }) => ({
        headerRight: () => {
          return <TopRightButton navigation={navigation} />;
        },
      })}
      initialRouteName={"Start"}
    >
      <Stack.Screen
        name={"Start"}
        options={{
          title: "Cargando",
        }}
        component={StartScreen}
      />
      <Stack.Screen
        name={"PendingUpload"}
        options={{
          title: "Registros locales",
          headerLeft: (props) => {
            //never show go back button on this page
            return null;
          },
        }}
        component={PendingUploadScreen}
      />
      <Stack.Screen
        name={"ClassSelection"}
        options={{
          title: "Nueva Actividad",
        }}
        component={ClassSelectionScreen}
      />
      <Stack.Screen
        name={"NewQuestion"}
        options={{
          title: "Ticket de Salida",
        }}
        component={NewQuestionScreen}
      />
      <Stack.Screen
        name={"NewOfficialQuestion"}
        options={{
          title: "Actividad/Ticket Oficial",
        }}
        component={NewOfficialQuestionScreen}
      />
      <Stack.Screen
        name={"Observation"}
        options={{
          title: "Observacion de clase",
        }}
        component={ObservationScreen}
      />
      <Stack.Screen
        name={"Video"}
        options={{
          title: "Comunidad de Aprendizaje",
        }}
        component={VideoScreen}
      />
      <Stack.Screen name={"Question"} component={EvaluationScreen} />
      <Stack.Screen
        name={"drawerStack"}
        options={{
          title: "Configuración",
        }}
        component={SettingsScreen}
      />
    </Stack.Navigator>
  );
}
