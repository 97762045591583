import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";

export const setInitialParams = (route, setState, setModalProperties) => {
  const { institucion_id, curso_id, nivel } = route.params;
  const promiseSubsectores = AsyncStorage.getItem(
    "@conectaIdeas:subsectores"
  ).then((subsectores) => JSON.parse(subsectores));
  const promiseOas = AsyncStorage.getItem("@conectaIdeas:oas").then((oas) =>
    JSON.parse(oas)
  );
  const promiseEjes = AsyncStorage.getItem("@conectaIdeas:ejes").then((ejes) =>
    JSON.parse(ejes)
  );

  Promise.all([promiseSubsectores, promiseOas, promiseEjes])
    .then((responses) => {
      const oas = responses[1];
      const subsectores = responses[0];
      const subsector_id = "1";
      //console.log('oas', oas);
      console.log("nivel", nivel);
      console.log("subsector", subsector_id);
      //console.log('subsectores', subsectores);
      let selectedNivel = nivel;
      if (_.isUndefined(oas[subsector_id][nivel])) {
        selectedNivel = _.chain(oas[subsector_id]).keys().first().value();
        console.log("nivel adjusted to", selectedNivel);
      }
      const firstEjeId = _.keys(oas[subsector_id][selectedNivel])[0];
      console.log("first eje", firstEjeId);
      const firstOaId = _.keys(oas[subsector_id][selectedNivel][firstEjeId])[0];
      console.log("first oa", firstOaId);
      const newState = {
        loaded: true,
        institucion_id,
        curso_id,
        eje_id: firstEjeId,
        nivel: selectedNivel,
        subsectores,
        subsector_id,
        oa_id: firstOaId,
        oas,
        ejes: responses[2],
        habilidadesId: [],
      };

      setState(newState);
    })
    .catch((exception) => {
      console.log("Error E4", exception.message);
      setModalProperties({
        titleModal: null,
        mensajeModal: "Error E4 " + exception.message,
        buttonModalRight: null,
        buttonModalLeft: "OK",
        isOpenModal: true,
        error: exception.message,
      });
    });
};
