// @flow
import { Auth } from "@aws-amplify/auth";
import { BetterPicker } from "@b9/react-native-ui-components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Picker } from "@react-native-community/picker";
import { useNavigation } from "@react-navigation/core";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Button, StyleSheet, View, Platform } from "react-native";

import Title from "../UI/Title";
import Modal from "../components/Modal";
import { getTicketPrehechos } from "../getTicketPrehechos";
import RenderActividadOficialButton from "./RenderActividadOficialButton";

const DataTickets = getTicketPrehechos();
export const CLASSES_KEY = "@conectaIdeas:classes";
export const INSTITUTIONS_KEY = "@conectaIdeas:institutions";

const nivelesPrehechos = _.chain(DataTickets)
  .map((d) => {
    return `${d.nivel}`;
  })
  .uniq()
  .value();

export default function ClassSelectionScreen() {
  const navigation = useNavigation();
  const [state, setState] = useState({
    institutions: [],
    classes: {},
    institution_id: undefined,
  });
  const [modalProperties, setModalProperties] = useState({
    buttonModalRight: null,
    buttonModalLeft: null,
    titleModal: null,
    mensajeModal: null,
    onPressButtonRight: null,
  });
  const [isOpenModal, setOpenModal] = useState(false);

  useEffect(() => {
    retrieveCursosFromDB()
      .then((data) => {
        const institutionId = data.institutions[0][0];
        setState({
          classes: data.classes,
          institutions: data.institutions,
          institution_id: institutionId,
          class_id: data.classes[institutionId][0].curso_id,
        });
      })
      .catch((exception) => {
        console.log("Did not refresh because", exception.message);
        AsyncStorage.clear().then(() => {
          const error =
            "Error: Usuario no posee permisos de profesor. E2 " +
            exception.message;
          console.log(error, exception);
          setModalProperties({
            ...modalProperties,
            titleModal: null,
            mensajeModal: error,
            buttonModalRight: null,
            buttonModalLeft: "OK",
            onPressButtonLeft: () => {
              setOpenModal(false);
              //signOut();
              AsyncStorage.clear();
              Auth.signOut();
            },
          });
          setOpenModal(true);
        });
      });
  }, []);

  const setInstitution = (institution_id) => {
    console.log("setInstitution", institution_id);

    const classes = state.classes[parseInt(institution_id, 10)];

    console.log("available classes on institution", classes);
    setState({
      ...state,
      institution_id,
      class_id: classes[0].curso_id,
    });
  };

  const setClass = (class_id) => {
    setState({ ...state, class_id });
  };

  const navigateTo = (target) => {
    const curso = _.find(state.classes[state.institution_id], {
      curso_id: state.class_id,
    });
    navigation.navigate(target, {
      institucion_id: state.institution_id,
      curso_id: curso.curso_id,
      nivel: curso.nivel,
    });
  };

  const classesForInstitution = (institution_id) => {
    if (institution_id !== undefined) {
      return state.classes[institution_id].map((i) => (
        <Picker.Item
          label={`${i.nivel}º ${i.nombre}`}
          value={String(i.curso_id)}
          key={String(i.curso_id)}
        />
      ));
    }
  };

  const retrieveCursosFromDB = async () => {
    const p1 = AsyncStorage.getItem(INSTITUTIONS_KEY);
    const p2 = AsyncStorage.getItem(CLASSES_KEY);
    return Promise.all([p1, p2]).then((values) => {
      if (values[0] === null) {
        return Promise.reject(new Error("DB doesn't have institutions"));
      }
      if (values[1] === null) {
        return Promise.reject(new Error("DB doesn't have classes"));
      }

      const v1 = JSON.parse(values[0]);
      const v2 = JSON.parse(values[1]);
      return {
        institutions: v1,
        classes: v2,
      };
    });
  };

  if (state.institution_id === undefined) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.loginForm}>
        <Title title="Selecciona escuela" />
        <BetterPicker
          style={styles.picker}
          selectedValue={String(state.institution_id)}
          onValueChange={setInstitution}
        >
          {state.institutions.map((i) => (
            <Picker.Item label={i[1]} value={String(i[0])} key={i[0]} />
          ))}
        </BetterPicker>
        <Title title="Selecciona curso" />
        <BetterPicker
          style={styles.picker}
          selectedValue={String(state.class_id)}
          onValueChange={setClass}
          key={state.institution_id}
        >
          {classesForInstitution(state.institution_id)}
        </BetterPicker>
      </View>

      <RenderActividadOficialButton
        classes={state.classes}
        institution_id={state.institution_id}
        class_id={state.class_id}
        nivelesPrehechos={nivelesPrehechos}
        navigateTo={navigateTo}
      />

      <Button
        title="Nuevo Ticket de Salida"
        onPress={() => navigateTo("NewQuestion")}
        testID="TicketSalida"
      />
      <View style={{ height: 20 }} />

      <Button
        title="Nueva Observación de Clase"
        onPress={() => navigateTo("Observation")}
        testID="Observation"
      />
      <View style={{ height: 20 }} />

      <Button
        title="Nuevo Video"
        onPress={() => navigateTo("Video")}
        testID="CreateVideo"
      />
      {isOpenModal && (
        <Modal
          title={modalProperties.titleModal}
          mensaje={modalProperties.mensajeModal}
          buttonRight={modalProperties.buttonModalRight}
          buttonLeft={modalProperties.buttonModalLeft}
          onPressButtonRight={modalProperties.onPressButtonRight}
          onPressButtonLeft={modalProperties.onPressButtonLeft}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5FCFF",
  },
  loginForm: {
    flexDirection: "column",
    width: Platform.OS === "web" ? "40%" : "98%",
    marginHorizontal: Platform.OS === "web" ? "20%" : "1%",
    paddingHorizontal: Platform.OS === "web" ? 10 : 5,
    alignItems: "center",
    paddingTop: "10%",
    paddingBottom: 20,
  },
  picker: {
    width: "98%",
  },
  row: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export const ClassesKey = () => CLASSES_KEY;
export const InstitutionsKey = () => INSTITUTIONS_KEY;
