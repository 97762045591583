import { useNavigation, useRoute } from "@react-navigation/core";
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { FlatList, Text, View } from "react-native";
import { Button } from "react-native-elements";

import ListObjetivos from "./ListObjetivos";
import RenderTotal from "./RenderTotal";
import StudentAnswer from "./StudentAnswer";
import { setInitialParams } from "./functions/setInitialParams";
import { store } from "./functions/store";
import { stylesEvaluation as styles } from "./styles";

const INITIAL_STATE = {
  oa: {},
  grades: {},
  students: [],
  curso: {
    nivel: null,
    nombre: null,
  },
  date: moment().format("DD-MM-YYYY HH:mm"),
  tipo: "",
};

export default function EvaluationScreen() {
  const navigation = useNavigation();
  const route = useRoute();
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    const initialParams = route.params;
    initialParams.grades =
      _.isUndefined(initialParams.grades) ||
      typeof initialParams.grades !== "object"
        ? {}
        : initialParams.grades;
    initialParams.date = _.isUndefined(initialParams.date)
      ? moment().format("DD-MM-YYYY HH:mm")
      : initialParams.date;
    initialParams.tipo = _.isUndefined(initialParams.tipo)
      ? "Ticket de Salida"
      : initialParams.tipo;

    const title = route.params.tipo ? "/" + route.params.tipo : "";

    navigation.setOptions({ title: `Ticket de Salida${title}` });
    setInitialParams(initialParams).then((newState) => {
      setState({ ...initialParams, ...newState });
    });
  }, [route, setState]);

  const updateAnswer = (student_id) => (event) => {
    setState((currentState) => {
      const newState = { ...currentState };
      newState.grades[student_id] = event;
      return newState;
    });
  };

  const _renderStudent = (data) => (
    <StudentAnswer student={data.item} onAnswer={updateAnswer(data.item.key)} />
  );

  const save = (state) =>
    store({
      oa: state.oa,
      grades: state.grades,
      curso: state.curso,
      date: state.date,
      key: state.key,
      habilidades: state.habilidadesId,
      question: state.question,
      tipo: state.tipo,
      prehecho_id: state.prehecho_id,
      subsector_id: state.subsector_id,
    }).then((output) => {
      console.log("DONE ready to move on EvaluationScreen", output);
      setState(INITIAL_STATE);
      navigation.navigate("PendingUpload");
    });
  if (_.isEmpty(state.students)) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View>
        <Text>
          <Text style={styles.text}>Escuela:</Text> {state.institucion}
        </Text>
        <Text>
          <Text style={styles.text}>Curso:</Text> {state.curso.nivel}º{" "}
          {state.curso.nombre}
        </Text>
        <Text>
          <Text style={styles.text}>Fecha: </Text>
          {state.date}
        </Text>
        <Text>
          <Text style={styles.text}>Subsector: </Text>
          {state.oa ? state.oa.subsector : state.ticket.subsector}
        </Text>
        <Text>
          <Text style={styles.text}>OA(s):</Text>{" "}
          {state.oa ? (
            state.oa.texto
          ) : (
            <ListObjetivos data={state.ticket.objetivos_aprendizajes} />
          )}
        </Text>
        <Text>
          <Text style={styles.text}>Pregunta:</Text> {state.question}
        </Text>
      </View>
      <FlatList data={state.students} renderItem={_renderStudent} />
      <RenderTotal absolute={true} grades={state.grades} />
      <RenderTotal absolute={false} grades={state.grades} />

      <Button title="Guardar evaluación" onPress={() => save(state)} />
    </View>
  );
}
