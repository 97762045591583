// @flow
import { useNavigation } from "@react-navigation/core";
import _ from "lodash";
import React, { useState } from "react";
import { View, Text, TextInput, Button } from "react-native";

import parameters from "../../app.json";
import Title from "../UI/Title";
import Modal from "../components/Modal";
import Screens from "../constants/Screens";
import useConnectionStatus from "../hooks/useConnectionStatus";
import { getInstitutionsList } from "./functions/loginFunctions";
import { styles } from "./styles";

export default function FirstLoginScreen() {
  const navigation = useNavigation();
  const [name, onChangeName] = useState(null);
  const [isError, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const isOnline = useConnectionStatus();

  const checkUser = async () => {
    setLoading(true);

    const institutions = await getInstitutionsList(name);
    if (_.isEmpty(institutions)) {
      setLoading(false);
      setError(true);
    }

    if (institutions.length === 1) {
      setLoading(false);
      navigation.navigate(Screens.ThirdLogin, {
        user: name,
        institucion: institutions[0].id,
      });
    }

    if (institutions.length > 1) {
      setLoading(false);
      navigation.navigate(Screens.SecondLogin, {
        user: name,
        instituciones: institutions,
      });
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.loginForm}>
        <Title title={"Nombre de usuario"} />
        <TextInput
          style={styles.input}
          autoFocus
          autoCapitalize={"none"}
          returnKeyType={"done"}
          autoCorrect={false}
          placeholder="Nombre de usuario"
          onChangeText={(text) => onChangeName(text)}
          testID="Username"
        />
        <View style={{ height: 40 }} />

        <Button
          title={loading ? "Buscando Institución..." : "Continuar"}
          style={styles.loginButton}
          onPress={checkUser}
          testID="Login"
          accessible={!(name === null)}
          disabled={name === null || loading}
        />
      </View>
      <Text style={styles.version}>v. {parameters.expo.version}</Text>
      {isError && (
        <Modal
          onPressButtonLeft={() => setError(false)}
          buttonLeft="OK"
          mensaje={
            isOnline
              ? "Nombre de usuario incorrecto."
              : "Sin conexión a internet"
          }
        />
      )}
    </View>
  );
}
