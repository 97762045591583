import _ from "lodash";
import React from "react";
import { Button, View } from "react-native";

export default function RenderActividadOficialButton({
  classes,
  institution_id,
  class_id,
  nivelesPrehechos,
  navigateTo,
}) {
  const curso = _.find(classes[institution_id], {
    curso_id: class_id,
  });

  if (nivelesPrehechos.includes(curso.nivel)) {
    console.log("Rendering actividad oficial button");
    return (
      <View>
        <Button
          title="Nueva Actividad/Ticket Oficial"
          onPress={() => navigateTo("NewOfficialQuestion")}
          testID="TicketSalidaOficial"
        />
        <View style={{ height: 20 }} />
      </View>
    );
  }

  return null;
}
