import { BetterPicker } from "@b9/react-native-ui-components";
import { Picker } from "@react-native-community/picker";
import _ from "lodash";
import React from "react";
import { View } from "react-native";

import Title from "../UI/Title";

const PLACEHOLDER = "Selecciona una alternativa";

export default function RenderQuestion({ data, setState, questionsList }) {
  return (
    <View>
      <Title title={data.question} />
      <BetterPicker
        placeholder={PLACEHOLDER}
        selectedValue={data.answer}
        onValueChange={(change) => {
          console.log("valueChange", data.question, change);
          if (change !== PLACEHOLDER) {
            const questions = [...questionsList];
            console.log("cloned questions", questions);
            const currentQuestion = _.find(questions, {
              question: data.question,
            });
            currentQuestion.answer = change;
            console.log("currentQuestion", currentQuestion);
            const answers = _.filter(questions, (q) => {
              return q.answer !== PLACEHOLDER;
            }).length;
            console.log("answers", answers);
            setState({
              questions,
              answers,
              buttonDisabled: answers !== questions.length,
            });
          }
        }}
      >
        {_.map(data.choices, (choice) => {
          return <Picker.Item label={choice} value={choice} key={choice} />;
        })}
      </BetterPicker>
    </View>
  );
}
